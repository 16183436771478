<template>
  <el-col :lg="layout.col_lg" :xl="layout.col_xl">
    <div class="position-relative article-wrap">
      <div class="article-preview">
        <!--    Favorite icon    -->
        <div class="favorite-circle">
          <i
            :class="{'active icon-heart-2': article.is_favourite, 'icon-heart': !article.is_favourite}"
            @click="processFavourite"
          />
        </div>
        <!--   Article     -->
        <a
          v-if="article.is_external"
          v-bind:href="article.translation.external_link"
          target="_blank" rel="noopener noreferrer"
        >
          <div :class="[layout.container_class, 'article-image-container']">
            <img
              v-if="article.translation.cover_image"
              v-lazy="article.translation.cover_image"
              :class="[layout.img_class, 'article-image']"
            >
            <img
              v-else
              src="@/assets/images/promo-blog.png"
              loading="lazy"
              class="article-image"
            >
            <div class="article-expired-overlay" v-if="article.expired" v-t="'news.article_expired'"></div>
          </div>
          <div :class="[layout.title_class, 'article-title']">
            <div>{{ article.translation.title }}</div>
          </div>
        </a>
        <router-localized-link v-else :to="articleRoute">
          <div :class="[layout.container_class, 'article-image-container']">
            <img
              v-if="article.translation.cover_image"
              v-lazy="article.translation.cover_image"
              :class="[layout.img_class, 'article-image']"
              alt=""
            >
            <img
              v-else
              src="@/assets/images/promo-blog.png"
              class="article-image"
              loading="lazy"
              alt=""
            >
            <div class="article-expired-overlay" v-if="article.expired" v-t="'news.article_expired'"/>
          </div>
          <div :class="[layout.title_class, 'article-title']">
            <div>{{ article.translation.title }}</div>
          </div>
        </router-localized-link>
      </div>
      <div class="position-absolute article-category-image-container">
        <img v-if="article.category.image_url" v-lazy="article.category.image_url" alt="">
      </div>
    </div>
  </el-col>
</template>

<script>

import RouterLocalizedLink from '@/components/common/RouterLocalizedLink';
import {needAuth} from '@/mixins/auth/needAuth';


export default {
  name: 'news-preview',
  components: {RouterLocalizedLink},
  mixins: [needAuth],
  props: {
    article: Object,
    layout: Object
  },
  computed: {
    articleRoute() {
      return {
        name: 'news.article',
        params: {
          categorySlug: String(this.article.category.translation.slug),
          articleSlug: String(this.article.translation.slug),
        }
      };
    }
  },
  methods: {
    processFavourite() {
      if (this.triggerLoginDialog()) {
        return;
      }

      if (this.article.is_favourite) {
        this.$store.dispatch('article/REMOVE_FAVORITE', this.article.id);
      } else {
        this.$store.dispatch('article/SET_FAVORITE', this.article.id)
      }
    }
  }
}
</script>
