<template>
  <div v-if="x2"
       class="folder-item-area"
       @click="openLink"
       :style="style"></div>
</template>

<script>

export default {
  name: 'folder-animation-link',
  props: ['url', 'position', 'containerWidth', 'containerHeight'],
  computed: {
    x1() {
      return this.containerWidth * this.position.x1 / 100;
    },
    x2() {
      return this.containerWidth * this.position.x2 / 100;
    },
    y1() {
      return this.containerHeight * this.position.y1 / 100;
    },
    y2() {
      return this.containerHeight * this.position.y2 / 100;
    },
    style() {
      return {
        left: this.x1 + 'px',
        top: this.y1 + 'px',
        width: this.x2 - this.x1 + 'px',
        height: this.y2 - this.y1 + 'px',
      }
    }
  },
  methods: {
    openLink() {
      window.open(this.url, '_blank');
    }
  }
}
</script>

<style lang="scss" scoped>
.folder-item-area {
  cursor: pointer;
  position: absolute;
  z-index: 105;
}

.folder-item-area:hover {
  border: 1px solid white;
  opacity: 0.25;
  background-color: black;
}
</style>
