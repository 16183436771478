import router from '@/router/index';

const getDefaultState = () => {
    return {
        active_filters: {
            reset_filter: false,
            promos_filter: false,
            types_filter: false,
            home_filter: false,
            categories_filter: false,
            ch_filter: false, // categories_header
            webshops_filter: false,
            stores_filter: false,
            brands_filter: false,
            price_filter: false,
            search_filter: false,
            favorite_filter: false,
            notifications_filter: false,
        },
        opened_filters: {
            promos_filter: true,
            types_filter: true,
            home_filter: true,
            categories_filter: true,
            ch_filter: true,
            webshops_filter: false,
            stores_filter: false,
            brands_filter: false,
            price_filter: false,
            search_filter: true,
            favorite_filter: true,
            notifications_filter: true,
        },
        filters_values: {
            promos_filter: [],
            types_filter: [],
            home_filter: [],
            morphable_types_filter: [],
            categories_filter: [],
            ch_filter: [],
            webshops_filter: [],
            stores_filter: [],
            brands_filter: [],
            price_filter: [],
            search_filter: undefined,
            favorite_filter: [],
            notifications_filter: [],
        },
        filters_animation: {
            reset_filter: false,
            promos_filter: false,
            types_filter: false,
            home_filter: false,
            categories_filter: false,
            ch_filter: false,
            webshops_filter: false,
            stores_filter: false,
            brands_filter: false,
            price_filter: false,
            search_filter: false,
            favorite_filter: false
        },
        filters_vuex_sources: {
            types_filter: 'promo',
        }
    }
};

export default {
    namespaced: true,
    state: getDefaultState(),

    getters: {
        ACTIVE_FILTERS(state) {
            return state.active_filters;
        },
        OPENED_FILTERS(state) {
            return state.opened_filters;
        },
        FILTERS_VALUES(state) {
            return state.filters_values;
        },
        FILTERS_ANIMATION(state) {
            return state.filters_animation;
        },
        FILTERS_VUEX_SOURCES(state) {
            return state.filters_vuex_sources;
        },
    },

    actions: {
        async SET_FILTER_ACTIVE({commit}, key) {
            await commit('UPDATE_FILTER_ACTIVE', key);
        },

        async SET_FILTER_INACTIVE({commit}, key) {
            await commit('UPDATE_FILTER_INACTIVE', key);
        },

        async SET_FILTER_OPENED({commit}, filter) {
            await commit('UPDATE_FILTER_OPENED', filter);
        },

        async SET_FILTER_VALUE({commit}, filter) {
            await commit('UPDATE_FILTER_VALUE', filter);
            await commit('UPDATE_URL', filter);
        },

        async SET_FILTER_VUEX_SOURCE({commit}, filter) {
            await commit('UPDATE_FILTER_VUEX_SOURCE', filter);
        },

        async RESET_FILTERS({commit}, withoutUrlParams = false) {
            await commit('SET_DEFAULT_FILTERS', withoutUrlParams);
        },

        async SET_ANIMATION_ACTIVE({commit}, key) {
            await commit('UPDATE_ANIMATION_ACTIVE', key);
        },

        async SET_ANIMATION_INACTIVE({commit}, key) {
            await commit('UPDATE_ANIMATION_INACTIVE', key);
        },
    },

    mutations: {
        UPDATE_FILTER_ACTIVE(state, key) {
            return _.set(state, 'active_filters.' + key, true);
        },

        UPDATE_FILTER_INACTIVE(state, key) {
            return _.set(state, 'active_filters.' + key, false);
        },

        UPDATE_FILTER_OPENED(state, filter) {
            return _.assign(state.opened_filters, filter);
        },

        UPDATE_FILTER_VUEX_SOURCE(state, filter) {
            return _.assign(state.filters_vuex_sources, filter);
        },

        UPDATE_FILTER_VALUE(state, filter) {
            return _.assign(state.filters_values, filter);
        },

        UPDATE_URL(state, filter) {
            const query = _.clone(router.currentRoute.value.query);
            _.forEach(filter, function (filterValue, filterKey) {
                if (_.isArray(_.get(getDefaultState(), `filters_values.${filterKey}`, false))) {
                    query[filterKey + '[]'] = _.toArray(filterValue);
                } else {
                    if (!_.isEmpty(filterValue)) {
                        query[filterKey] = filterValue;
                    } else {
                        delete query[filterKey];
                    }
                }
            });

            const r = router.replace({query: query})

            if (!Object.keys(this.state.auth.user).length) {
                r.then(() => {
                    window.location.reload()
                });
            }
        },

        SET_DEFAULT_FILTERS(state, withoutUrlParams) {
            const defaultState = getDefaultState();

            if (withoutUrlParams) {
                return _.assign(state, defaultState);
            }
            _.forEach(_.keys(defaultState.filters_values), function (filterKey) {
                if (_.isArray(_.get(getDefaultState(), `filters_values.${filterKey}`, false))) {
                    let value = router.currentRoute.value.query[filterKey + '[]'];
                    if (value !== undefined) {
                        value = _.castArray(value);
                        _.set(defaultState, 'filters_values.' + filterKey, value);
                    }

                } else {
                    const value = router.currentRoute.value.query[filterKey];
                    if (value !== undefined && typeof value === 'string' && value.length) {
                        _.set(defaultState, 'filters_values.' + filterKey, value);
                    }
                }
            });

            return _.assign(state, defaultState);
        },

        UPDATE_ANIMATION_ACTIVE(state, key) {
            return _.set(state, 'filters_animation.' + key, true);
        },

        UPDATE_ANIMATION_INACTIVE(state, key) {
            return _.set(state, 'filters_animation.' + key, false);
        },
    }
}
