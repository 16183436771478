<template>
  <default-grid :show-right-container="false">
    <template v-slot:left-container>
      <left-bar show-folders-stores></left-bar>
    </template>
    <template v-slot:main-container>
      <folders-feed></folders-feed>

      <div class="folders-feed my-5" v-html="pageContent"></div>
    </template>
  </default-grid>
</template>

<script>
import DefaultGrid from '@/views/DefaultGrid';
import {mapActions, mapGetters} from 'vuex';
import FoldersFeed from '@/components/feed/FoldersFeed';
import LeftBar from '@/components/left_bar/LeftBar';
import {dropper} from '@/mixins/vuex/dropper';

export default {
  name: 'FoldersView',
  components: {
    LeftBar,
    FoldersFeed,
    DefaultGrid,
  },
  mixins: [dropper],
  data() {
    return {
      //
    }
  },
  computed: {
    ...mapGetters('page', [
      'PAGES_LOADED',
      'PAGES'
    ]),
    ...mapGetters('store', [
      'STORES_LOADED'
    ]),

    pageContent() {
      const thisElem = this;
      const pages = _.filter(this.PAGES, function (need) {
        return _.get(need, 'locale.slug') === thisElem.$i18n.locale && _.get(need, 'slug') === 'folders';
      });

      return _.get(pages, '[0]content', '');
    }
  },
  beforeMount() {
    if (!this.PAGES_LOADED) {
      this.FETCH_PAGES();
    }
    if (!this.STORES_LOADED) {
      this.FETCH_STORES();
    }
    this.resetAllPromosModules();
    this.resetAllFoldersModules();
    this.RESET_FILTERS();

    this.SET_FILTER_ACTIVE('stores_filter');
    this.SET_FILTER_ACTIVE('categories_filter');

    this.SET_FILTER_OPENED({categories_filter: false});
    this.SET_FILTER_OPENED({stores_filter: false});
  },
  beforeUnmount() {
    this.RESET_FILTERS();
  },
  methods: {
    ...mapActions('filter', [
      'SET_FILTER_ACTIVE',
      'SET_FILTER_OPENED',
      'RESET_FILTERS',
    ]),
    ...mapActions('page', [
      'FETCH_PAGES',
    ]),
    ...mapActions('store', [
      'FETCH_STORES',
    ]),
  }
}
</script>
