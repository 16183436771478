<template>
  <div v-if="prizeLoaded">
    <div class="text-center font-weight-bold mb-1">
      {{ PRIZE.price }} <span v-t="'prizes.points'"></span>
    </div>

    <div class="my-2 text-center">
      <img class="prize-image" v-lazy="PRIZE.image_url" alt="">
    </div>
    <div class="my-2 text-center font-weight-bold" v-text="PRIZE.translation.title"></div>
    <div class="my-2 text-center" v-t="'prizes.request_form.prize_request_description'"></div>

    <el-row type="flex" justify="center">
      <el-col :xs="24" :sm="18" :md="12" :lg="24" :xl="24">

        <el-form ref="form" :model="form" :rules="rules" label-position="top">

          <el-row>
            <el-col :span="12" :xs="24">
              <el-form-item :label="$t('prizes.request_form.email')" prop="email">
                <el-input class="rounded-input"
                          v-model="form.email"
                          :placeholder="$t('prizes.request_form.email')"
                          disabled></el-input>
              </el-form-item>
            </el-col>
          </el-row>

          <el-row>
            <el-col :span="12" :xs="24">
              <div class="me-3">
                <el-form-item :label="$t('prizes.request_form.street')" prop="street">
                  <el-input class="rounded-input"
                            v-model="form.street"
                            :placeholder="$t('prizes.request_form.street')"></el-input>
                </el-form-item>
              </div>
            </el-col>

            <el-col :span="6" :xs="12">
              <div class="me-3">
                <el-form-item :label="$t('prizes.request_form.number')" prop="number">
                  <el-input class="rounded-input"
                            v-model="form.number"
                            :placeholder="$t('prizes.request_form.number')"></el-input>
                </el-form-item>
              </div>
            </el-col>

            <el-col :span="6" :xs="12">
              <div class="me-3">
                <el-form-item :label="$t('prizes.request_form.bus')" prop="bus">
                  <el-input class="rounded-input"
                            v-model="form.bus"
                            :placeholder="$t('prizes.request_form.bus')"></el-input>
                </el-form-item>
              </div>
            </el-col>
          </el-row>

          <el-row>
            <el-col :span="12">
              <div class="me-3">
                <el-form-item :label="$t('prizes.request_form.postal_code')" prop="postal_code">
                  <el-input class="rounded-input"
                            v-model="form.postal_code"
                            :placeholder="$t('prizes.request_form.postal_code')"></el-input>
                </el-form-item>
              </div>
            </el-col>

            <el-col :span="12">
              <div class="me-3">
                <el-form-item :label="$t('prizes.request_form.city')" prop="city">
                  <el-input class="rounded-input"
                            v-model="form.city"
                            :placeholder="$t('prizes.request_form.city')"></el-input>
                </el-form-item>
              </div>
            </el-col>
          </el-row>

          <div>
            <el-form-item prop="agreement">
              <el-checkbox v-model="form.agreement"></el-checkbox>
              <span v-t="'prizes.request_form.agreement_text'"></span>
              <span v-t="'prizes.request_form.agreement_link'"
                    class="color-primary cursor-pointer">algemene voorwaarden</span>
            </el-form-item>
          </div>

          <div class="text-center my-3">
            <el-button type="success" @click="submit">
              <span v-t="'prizes.request_form.submit_btn'"></span>
            </el-button>
          </div>
        </el-form>

      </el-col>
    </el-row>
  </div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex';
import {validator} from '@/mixins/validator';

export default {
  name: 'prize-request-form',
  mixins: [validator],
  data() {
    return {
      form: {
        email: '',
        street: '',
        number: '',
        bus: '',
        city: '',
        postal_code: '',
        agreement: false,
      },
      rules: {
        email: [
          {required: true, validator: this.backendValidator}
        ],
        street: [
          {required: true, validator: this.backendValidator}
        ],
        number: [
          {required: true, validator: this.backendValidator}
        ],
        bus: [
          {validator: this.backendValidator}
        ],
        city: [
          {required: true, validator: this.backendValidator}
        ],
        postal_code: [
          {required: true, validator: this.backendValidator}
        ],
        agreement: [
          {required: true, validator: this.backendValidator}
        ],
      }
    };
  },
  mounted() {
    this.FETCH_PRIZE(this.$route.params.prizeId);
    this.form.email = this.USER.email;
    this.form.postal_code = this.USER.postal_code;
  },
  computed: {
    ...mapGetters('prize', [
      'PRIZE',
    ]),
    ...mapGetters('auth', [
      'USER',
    ]),

    prizeLoaded() {
      return !_.isEmpty(this.PRIZE);
    }
  },
  methods: {
    ...mapActions('prize', [
      'FETCH_PRIZE',
    ]),
    ...mapActions('loader', [
      'SET_LOADER',
    ]),

    submit() {
      this.SET_LOADER(true);
      this.$http.post(`prizes/${this.PRIZE.id}/buy`, this.form).then((response) => {
        this.errors = [];

        this.$notify.success({
          title: this.$t('notifications.prize_request_created.title'),
          message: this.$t('notifications.prize_request_created.message'),
          position: this.notificationPosition,
          duration: this.notificationDuration
        });

        this.routerPush({name: 'prizes-pool'});
      }).catch((e) => {
        switch (e.response.status) {
          case 422:
            this.errors = _.assign({}, e.response.data.errors);
            break;

          default:
            this.$notify.error({
              title: this.$t('notifications.default.title'),
              message: _.get(e.response, 'data.error', this.$t('notifications.default.message')),
              position: this.notificationPosition,
              duration: this.notificationDuration,
            });
        }
      }).then(() => {
        this.$refs.form.validate((valid) => {
          this.SET_LOADER(false);
        });
      });
    }
  },
}
</script>

<style>
.prize-image {
  height: 200px;
}
</style>
