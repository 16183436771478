<template>
  <default-grid :show-right-container="false">
    <template v-slot:left-container>
      <left-bar show-store-logo show-store-mailer :show-filters-list="false"></left-bar>
    </template>
    <template v-slot:main-container>
      <div class="promos-feed">
        <div class="d-flex align-items-center header-container">
          <h1 class="promos-feed-title" v-html="h1Text"></h1>
        </div>

        <tabs
          ref="tabs_component"
          :tabs="tabs"
          :default="currentTab"
          @change="tabChange"
          change-query
        />
        <div>
          <div class="mt-3 mt-md-0">
            <search-promos-list
              :items="PROMOS"
              :current-tab="currentTab"
              :vuex-modules="{'post': 'promo', 'folder_promo': 'promo'}"
            />

            <div v-if="!PROMOS_PROMISE && !PROMOS_IS_COMPLETE" class="w-100 centered my-5 pb-5">
              <el-button type="primary"
                         :class="{'w-75':xsOnly,'w-50':!xsOnly}"
                         @click="loadResults">
                <span v-t="'webshop.load_more'"></span>
              </el-button>
            </div>
          </div>

          <h2 class="color-primary my-3" :class="{'font-size-18':xsOnly,'font-size-20':!xsOnly}" v-t="'webshop.seo.top_questions'"></h2>

          <div v-for="(faqItem, key) in faqData" :key="key">
            <el-divider class="my-3"/>

            <div class="font-size-20">
              <i class="icon-minus"></i>&nbsp; <span v-text="faqItem.question"></span>
            </div>

            <div class="font-size-14 m-3" v-html="faqItem.answer"/>
          </div>

          <div class="my-5 seo-content" v-html="CURRENT_STORE.translation.seo_content"/>

          <div v-if="last10PostsTableData.length">
            <h3 class="h4" v-html="$t('store.seo.table_header',{store: CURRENT_STORE.translation.title})"/>

            <div class="mb-5">
              <el-table :data="last10PostsTableData" border>
                <el-table-column prop="published_at" :label="$t('store.seo.table.row_1')" :min-width="25"/>
                <el-table-column prop="short_content" :label="$t('store.seo.table.row_2')" :min-width="75"/>
              </el-table>
            </div>
          </div>
        </div>
      </div>
    </template>
  </default-grid>
</template>

<script>
import Tabs from '@/components/feed/Tabs';
import LeftBar from '@/components/left_bar/LeftBar';
import DefaultGrid from '@/views/DefaultGrid';
import {mapActions, mapGetters} from 'vuex';
import {useHead} from '@vueuse/head';
import {dates} from '@/mixins/dates';
import {breadcrumbs} from '@/mixins/seo/breadcrumbs';
import StoreRoute from '@/router/guards/StoreRoute';
import SearchPromosList from '@/components/feed/SearchPromosList';
import {store_webshop_brand_product} from '@/mixins/group/store_webshop_brand_product';

export default {
  name: 'StoreView',
  components: {
    SearchPromosList,
    Tabs,
    LeftBar,
    DefaultGrid
  },
  mixins: [dates, breadcrumbs, store_webshop_brand_product],
  data() {
    return {
      currentTab: _.get(this.$route.query, 'tab', 'default'),
      fetchOptions: {
        type_kind_not: this.$consts.KIND.QUESTION,
        include_filters: ['types'],
        types: [],
        morphable_types: [],

        q: '',
        per_page: 15,
      },
      faqData: []
    }
  },
  computed: {
    ...mapGetters('store', [
      'CURRENT_STORE',
    ]),
    /**
     * Tabs
     * @returns {{}}
     */
    tabs() {
      const tabs = {};
      tabs['default'] = this.$t('store.tabs.default');
      tabs['folder-promos'] = this.$t('store.tabs.folder-promos');
      tabs['posts'] = this.$t('store.tabs.posts');
      return tabs;
    },
    /**
     * Title text
     * @returns {*}
     */
    h1Text() {
      const date = new Date();
      const m = date.toLocaleString(this.$i18n.locale, {month: 'long'});
      const y = date.getFullYear();
      return this.$t('store.store_page_h1', {store: this.CURRENT_STORE.translation.title, month: m, year: y});
    },

    last10PostsTableData() {
      const thisElem = this;
      return _.map(this.CURRENT_STORE.last_posts, function (post) {
        return {
          published_at: thisElem.dateStringFromTimestamp(_.get(post, 'published_at.timestamp', null)),
          short_content: post.short_content,
        }
      });
    }
  },
  // need this because Guards doesnt work when the same route params changing
  async beforeRouteUpdate(to, from, next) {
    if (from.params.storeSlug !== to.params.storeSlug) {
      await StoreRoute(to, from, next);
    }
    next();
  },
  created() {
    useHead({
      // Can be static or computed
      script: [
        {
          type: 'application/ld+json',
          children: JSON.stringify(this.getFaqStructure())
        },
        {
          type: 'application/ld+json',
          children: JSON.stringify(this.getBreadcrumbStructure())
        },
      ],
    })
  },
  methods: {
    prepareFetchOptions() {
      this.SET_FILTER_ACTIVE('types_filter');

      this.fetchOptions.store = this.CURRENT_STORE.id;
      this.fetchOptions.types = this.FILTERS_VALUES.types_filter;
      this.fetchOptions.order = 'codes_first'

      switch (this.currentTab) {
        case 'folder-promos':
          this.SET_FILTER_INACTIVE('types_filter');
          this.fetchOptions.types = [];
          this.fetchOptions.morphable_types = ['FolderItem']
          break;
        case 'posts':
          this.fetchOptions.morphable_types = ['Post']
          break;
        case 'default':
        default:
          this.fetchOptions.morphable_types = []
          break;
      }
    },
    getFaqStructure() {
      const params = {
        'store': this.CURRENT_STORE.translation.title,
        'total_posts': this.CURRENT_STORE.total_posts,
        'last_post_content': this.CURRENT_STORE.last_post_content,
      };
      const mainEntity = [];
      this.faqData = [];
      for (let i = 1; i <= 4; i++) {
        mainEntity.push({
          '@type': 'Question',
          'name': this.$t('store.seo.question_' + i, params),
          'acceptedAnswer': {
            '@type': 'Answer',
            'text': this.$t('store.seo.answer_' + i, params)
          }
        });
        this.faqData.push({
          question: this.$t('store.seo.question_' + i, params),
          answer: this.$t('store.seo.answer_' + i, params)
        });
      }
      return {
        '@context': 'https://schema.org',
        '@type': 'FAQPage',
        'mainEntity': mainEntity
      };
    }
  }
}
</script>
