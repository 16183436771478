<template>
  <div>
    <div class="input-form">
      <!--
        Avatar
      -->
      <div class="input-form-avatar-wrapper">
        <profile-preview-dialog :profile-id="USER.id">
          <el-avatar :src="avatarImage" shape="circle" class="input-form-avatar cursor-pointer"/>
        </profile-preview-dialog>
      </div>

      <!--
        Textarea
      -->
      <at-ta :members="atMembers" name-key="name" @at="at" :filterMatch="atFilterMatch">
        <!--
          Emoji & Upload image icon
        -->
        <div class="position-relative">
          <div class="actions-container" :style="actionsStyle">
            <!--  Emoji  -->
            <div class="emoji-image-btn">
              <i
                v-if="GUEST"
                @click="triggerLoginDialog"
                class="icon-emoji"
              />
              <emoji-picker v-else @select="selectEmoji($event)">
                <i class="icon-emoji"/>
              </emoji-picker>
            </div>
            <!--  Upload icon  -->
            <div class="upload-image-btn">
              <i class="icon-camera-2" @click="uploadImage"/>
            </div>
          </div>
        </div>

        <template #item="s">
          <img :src="s.item.avatar_url">
          <span v-text="s.item.full_name" />
        </template>

        <slot name="textarea">
          <el-input
            class="rounded-textarea input-textarea--default indent-text"
            type="textarea"
            v-model="content"
            :placeholder="placeholder"
            :autosize="{ minRows: 1, maxRows: 5}"
            ref="content"
            @blur="inputFocused = false"
            @submit="submit"
            @focus="inputFocusEvent"
            @paste="pasteEvent"
          />
        </slot>
      </at-ta>

      <!--
        Slot submit button
      -->
      <slot name="submit-button"/>
    </div>

    <!--
      Upload image
    -->
    <el-upload
      ref="upload"
      class="upload-image-container"
      :action="imageAction"
      :headers="{'Accept':'application/json','Authorization':'Bearer ' + AUTH_TOKEN}"
      :on-remove="handleImageRemove"
      :on-error="handleImageError"
      :on-change="handleImageOnChange"
      :limit="imagesLimit"
      list-type="picture"
      :file-list="fileList"
    >
      <span ref="upload_element"/>
    </el-upload>
  </div>
</template>

<script>
import AtTa from 'vue-at/dist/vue-at-textarea'
import {mapGetters} from 'vuex';
import EmojiPicker from '@/components/feed/post/EmojiPicker';
import {needAuth} from '@/mixins/auth/needAuth';
import ProfilePreviewDialog from '@/components/feed/profile/ProfilePreviewDialog';

export default {
  name: 'InputForm',
  components: {ProfilePreviewDialog, EmojiPicker, AtTa},
  mixins: [needAuth],
  props: {
    placeholder: {
      type: String,
      default: ''
    },
    actionsPosition: {
      type: String,
      default: 'left'
    },
    imagesLimit: {
      type: Number,
      default: 1
    },
    imageAction: {
      type: String,
      default: '/'
    },
  },
  data() {
    return {
      content: '',
      images: [],
      inputFocused: false,
      fileList: [],
      atMembers: [],
      inputDebounce: _.debounce((q) => {
        this.axios.get('/users/search?q=' + q).then((response) => {
          this.atMembers = response.data.data;
        });
      }, 500),
    }
  },
  computed: {
    ...mapGetters('auth', [
      'USER',
      'GUEST',
      'AUTH_TOKEN',
    ]),
    actionsStyle() {
      return this.actionsPosition === 'right' ? {right: '5px'} : {left: '3px'}
    },
    /**
     * Avatar image
     * @returns {*}
     */
    avatarImage() {
      if (this.GUEST) {
        return require('@/assets/images/avatar.jpg');
      }
      return this.USER.avatar_url;
    },
  },
  methods: {
    at(q) {
      this.inputDebounce(q);
    },
    atFilterMatch(name, chunk, at) {
      name = name.toLowerCase().replaceAll(' ', '.');
      chunk = chunk.toLowerCase().replaceAll(' ', '.');
      // match at lower-case
      return name.indexOf(chunk) > -1
    },
    /**
     * Submit event
     */
    submit() {
      this.webshopLoginDialog();
      if (this.triggerLoginDialog()) {
        return;
      }
      this.$emit('submit', {content: this.content, images: this.images});
      this.resetForm();
    },
    /**
     * Handle image error
     * @param err
     * @param file
     * @param files
     */
    handleImageError(err, file, files) {
      const e = JSON.parse(err.message);

      this.$notify.error({
        title: this.$t('notifications.default.title'),
        message: _.get(e, 'errors.file.0', this.$t('notifications.default.message')),
        position: this.notificationPosition,
        duration: this.notificationDuration,
      });
    },

    handleImageOnChange(file, files) {
      this.images = [];

      if (_.get(file, 'response.file')) {
        this.images = _.get(file, 'response.file');
      }
    },

    handleImageRemove() {
      this.images = [];
      this.fileList = [];
    },

    uploadImage() {
      this.webshopLoginDialog();
      if (this.triggerLoginDialog()) {
        return;
      }
      this.$refs.upload_element.click();
    },

    resetForm() {
      this.content = '';
      this.images = [];
      this.fileList = [];
      this.$refs.upload.clearFiles();
    },

    selectEmoji(emoji) {
      const insertText = emoji.native;
      if (!insertText.length) return;

      const textarea = this.$refs.content.$refs.textarea;
      const sentence = textarea.value;
      const len = sentence.length;
      let pos = textarea.selectionStart;
      if (pos === undefined) {
        pos = 0;
      }

      const before = sentence.substr(0, pos);
      const after = sentence.substr(pos, len);

      this.content = before + insertText + after;

      this.$nextTick().then(() => {
        textarea.selectionStart = pos + insertText.length;
      });
    },

    pasteEvent(event) {
      this.webshopLoginDialog();
      if (this.triggerLoginDialog()) {
        return;
      }
      const items = event.clipboardData.items;
      for (const index in items) {
        const item = items[index];
        if (item.kind === 'file') {
          const imageFromClipboard = item.getAsFile();
          const formData = new FormData();
          formData.append('file', imageFromClipboard);

          axios.post(this.imageAction, formData).then((response) => {
            this.images = response.data.file;
            this.fileList = [{url: URL.createObjectURL(imageFromClipboard)}];
          }).catch((e) => {
            switch (e.response.status) {
              case 401:
                this.triggerLoginDialog();
                break;
              default:
                break;
            }
          });
          break;
        }
      }
    },

    inputFocusEvent() {
      this.webshopLoginDialog();
      if (this.triggerLoginDialog()) {
        this.$refs.content.blur();
        return;
      }
      this.inputFocused = true;
    }
  }
}
</script>
