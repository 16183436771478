<template>
  <div class="folder-announcement-container">
    <!--
      Navigation + Scrollbar
    -->
    <div class="navigation-line" ref="navigation_line">
      <div class="nav-buttons">
        <button
          v-if="isLeftArrowActive"
          class="float-start"
          ref="leftArrow"
          @click="scrollPrev"
        >
          <i class="icon-triangle-arrow icon-flipped"/>
        </button>

        <el-scrollbar
          :native="false"
          ref="scrollbar_ref"
          :min-size="40"
          v-loading="isLoading"
          @touchstart="handleTouchStart"
          @touchmove="handleTouchMove"
          @touchend="handleTouchEnd"
        >
          <div class="scrollbar-flex-content">
            <div
              v-for="(folder, i) in folders"
              :key="i"
              class="scrollbar-item announcement"
              :style="previewItemContainerStyle"
            >
              <el-row class="px-lg-5">
                <!--    Image section   -->
                <el-col :span="12" class="d-flex justify-content-center pe-2 pe-sm-0">
                  <div class="announcement__image-wrapper">
                    <img
                        v-lazy="folder.image_url"
                        class="folder-image"
                        @click="goFolder(folder)"
                        alt=""
                    >
                  </div>
                </el-col>

                <!--    Content section   -->
                <el-col :span="12" class="ps-2 ps-sm-0">
                  <div class="announcement__content">
                    <div class="d-flex flex-column">
                      <p v-html="$t('folder.latest_brochure_online',{folder: folder.store.translation.title})"/>
                      <div class="button-wrapper">
                        <img v-lazy="'@/assets/images/folder-announcement.svg'" alt="" loading="lazy">
                        <button @click="goFolder(folder)">
                          {{ $t('promo.view_the_folder') }}!
                        </button>
                      </div>
                    </div>
                  </div>
                </el-col>
              </el-row>
            </div>
          </div>
        </el-scrollbar>

        <button
          :class="{'invisible': !isRightArrowActive}"
          class="float-end"
          ref="rightArrow"
          @click="scrollNext"
        >
          <i class="icon-triangle-arrow"/>
        </button>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'folder-announcement-carousel',
  props: {
    folders: Array,
    vuexModule: {
      type: String,
      default: 'folder',
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    customWidth: {
      type: String,
      default: '100%'
    },
  },
  data() {
    return {
      containerWidth: 0,
      currentScroll: 0,
      currentScrollPx: 0,
      loadingEmitted: false,
    }
  },
  computed: {
    /**
     * @returns {boolean}
     */
    isLeftArrowActive() {
      return this.currentScrollPx > 0
    },
    /**
     * @returns {boolean}
     */
    isRightArrowActive() {
      return this.currentScrollPx < this.maxScrollPx
    },
    /**
     * Pixels after visible zone
     * @returns {number}
     */
    maxScrollPx() {
      return this.folders.length * this.widthInPx - this.containerWidth;
    },
    /**
     * Style for folder container
     * @returns {{width: string}}
     */
    previewItemContainerStyle() {
      return {
        'width': this.widthInPx + 'px'
      }
    },
    /**
     * Width of one folder in px
     */
    widthInPx() {
      if (!this.containerWidth) {
        return;
      }

      let width = this.customWidth

      if (width.indexOf('%') !== -1) {
        width = width.replace(/%/g, '')

        return this.containerWidth * Number(width) / 100
      }

      width = width.replace(/[a-zA-Z]/g, '')

      return Number(width)
    },
    arrowWidth() {
      return this.xsOnly ? 23 : 28
    }
  },
  mounted() {
    new ResizeObserver(() => this.calculateContainerWidth()).observe(this.$refs.navigation_line);
  },
  methods: {
    handleTouchStart(event) {
      this.startX = event.touches[0].clientX;
    },
    handleTouchMove(event) {
      this.currentX = event.touches[0].clientX;
    },
    handleTouchEnd() {
      if (this.startX - this.currentX > 20) {
        this.scrollNext()
      } else if (this.currentX - this.startX > 20) {
        this.scrollPrev()
      }
    },
    scrolling(value) {
      this.currentScrollPx = value.scrollLeft;
    },
    calculateContainerWidth() {
      if (this.$refs.navigation_line) {
        this.containerWidth = this.$refs.navigation_line.clientWidth - this.arrowWidth;

        if (this.currentScroll !== 0) {
          this.containerWidth -= this.arrowWidth
        }
      }
    },
    scrollNext() {
      this.currentScroll++;
      this.$emit('next')
    },
    scrollPrev() {
      if (this.currentScroll > 0) {
        this.currentScroll--;
        this.$emit('prev')
      }
    },
    goFolder(folder) {
      if (this.isLinkedToStorePage) {
        this.routerPush({
          name: 'folders.store',
          params: {
            storeSlug: folder.store.translation.route
          }
        });
        return;
      }
      this.routerPush({
        name: 'folder.view',
        params: {
          storeSlug: folder.store.translation.route,
          folderDate: folder.valid_from,
        },
        query: {
          id: folder.id
        }
      });
    },
  },
  watch: {
    maxScrollPx(value) {
      if (value) {
        this.$emit('rendered', {name: 'folders', maxScrollPx: value})
      }
    },
    currentScroll(value) {
      this.calculateContainerWidth()
      this.currentScrollPx = this.widthInPx * value;
      this.$refs.scrollbar_ref.setScrollLeft(this.widthInPx * value);
    },
    currentScrollPx(value) {
      if (value > this.maxScrollPx && !this.loadingEmitted) {
        this.$emit('load');
        this.loadingEmitted = true;
        setTimeout(() => {
          this.loadingEmitted = false;
        }, 3000);
      }
    }
  }
}
</script>
