<template>
  <el-form @submit.prevent="submitSearch">
    <el-autocomplete
      v-model="query"
      ref="autocomplete"
      :fetch-suggestions="querySearch"
      class="search-input"
      :class="{'increased-padding': showCloseSuggestions}"
      popper-class="searchbar-popper"
      :placeholder="$t('header.search_placeholder')"
      :size="inputsSize"
      :fit-input-width="true"
      :debounce="650"
      @focus="handleFocus"
      @focusout="focusOut(100)"
      @focusin="emitter.emit('close-filter')"
      @select="handleSelect"
    >
      <template #prefix>
        <Transition name="slide-fade">
          <div v-if="showCloseSuggestions && !xsOnly" class="close-suggestions">
            <i class="icon-arrow-4 icon-flipped" @click="closeSuggestions"/>
            <el-divider direction="vertical" class="h-100"/>
          </div>
        </Transition>
      </template>

      <template #default="{ item }">
        <span v-if="item.type === 'header'" class="font-size-17 pe-2 font-weight-bold">{{ item.title }}:</span>
        <br v-else-if="item.type === 'space'">
        <template v-else>
          <i v-if="item.icon" class="font-size-16 pe-2" :class="item.icon"/>
          <i v-if="item.icon==='icon-starpopular'" class="icon-star-full font-size-16 pe-2"></i>
          <span class="font-size-16 pe-2">{{ item.title }}</span>
          <span v-if="item.groupKey" class="item-type small">({{ item.group }})</span>
        </template>
      </template>

      <template #suffix>
        <i class="icon-search" @click="submitSearch"/>
      </template>
    </el-autocomplete>
  </el-form>
</template>

<script>
import {autocompelete} from '@/mixins/autocompelete';
import {mapGetters} from 'vuex';

export default {
  name: 'UiAutocomplete',
  mixins: [autocompelete],
  data() {
    return {
      query: '',
    };
  },
  computed: {
    ...mapGetters('auth', [
      'USER',
      'GUEST',
      'LOCALE',
    ]),
    showCloseSuggestions() {
      return this.xsOnly && this.popperFocused
    },
  },
  methods: {
    /**
     * Close suggestions section
     */
    closeSuggestions() {
      return this.$refs.autocomplete.close()
    },
    clearSuggestions() {
      this.$refs.autocomplete.suggestions = [];
    },
    submitSearch() {
      if (this.triggerSuggest(this.query) || this.query.length < 3) {
        return;
      }
      this.clearSuggestions()

      const routeBody = {name: 'promos', query: {q: String(this.query)}};

      const elInputInner = this.$refs.autocomplete.inputRef.input;
      elInputInner.blur();
      document.activeElement.blur();

      if (this.GUEST) {
        window.open(this.$router.resolve(routeBody).href, '_self');
        return;
      }

      this.saveSearchTerm({query: this.query}).then(() => {
        this.routerPush(routeBody);
        this.query = ''
      })
    },
  },
  watch: {
    $route(to, from) {
      if ('search' !== to.name) {
        this.query = '';
      }
    },
  }
}
</script>
