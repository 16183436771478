<template>
  <div v-if="!CURRENT_PAGE">
    <div class="not-found-container">
      <div v-if="xsOnly" class="container-not-found-mobile">
        <div class="text not-found">404</div>
        <div class="oops" v-t="'header.not_found.oops'"></div>
        <div class="not-promo" v-t="'header.not_found.not_promo'"></div>
        <router-localized-link :to="{name : 'home'}">
          <div class="home-coming p-1">
            <div class="position-relative">
              <i v-t="'header.not_found.home'"></i>
              <div class="animation-icon-container">
                <span class="icon-chevron-thin-right"></span>
              </div>
            </div>
          </div>
        </router-localized-link>
      </div>

      <div class="container-not-found">
        <img class="treasury" v-lazy="'@/assets/images/treasury.svg'">
        <div v-if="!xsOnly" class="container-not-found-text">
          <div class="text not-found">404</div>
          <div class="oops" v-t="'header.not_found.oops'"></div>
          <div class="not-promo" v-t="'header.not_found.not_promo'"></div>
          <router-localized-link :to="{name : 'home'}">
            <div class="home-coming p-2">
              <div class="position-relative">
                <i v-t="'header.not_found.home'"></i>
                <div class="animation-icon-container">
                  <span class="icon-chevron-thin-right"></span>
                </div>
              </div>

            </div>
          </router-localized-link>
        </div>
        <img class="promohunter-flashlight-beam align-items-center" v-lazy="'@/assets/images/promohunter_flashlight_beam.svg'">
      </div>
    </div>
  </div>

  <default-grid v-else :show-left-container="false" :show-right-container="false">
    <template v-slot:main-container>
      <page v-if="CURRENT_PAGE" :page-object="CURRENT_PAGE"></page>
    </template>
  </default-grid>
</template>

<script>
import {mapActions, mapGetters} from 'vuex';
import DefaultGrid from '@/views/DefaultGrid';
import Page from '@/components/feed/Page';
import RouterLocalizedLink from '@/components/common/RouterLocalizedLink';
import PageRoute from '@/router/guards/PageRoute';

export default {
  name: 'PageNotFoundHandlerView',
  components: {
    Page,
    DefaultGrid, 
    RouterLocalizedLink
  },
  data() {
    return {}
  },
  computed: {
    ...mapGetters('page', [
      'CURRENT_PAGE'
    ]),
  },

  // need this because Guards doesnt work when the same route params changing
  async beforeRouteUpdate(to, from) {
    if (_.get(from.params, 'pathMatch[0]', false) !== _.get(to.params, 'pathMatch[0]', false)) {
      await PageRoute(to);
    }
  },
}
</script>
