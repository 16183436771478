

<template>
  <span v-if="storeTitle" class="store-label line-clamp-1" v-text="storeTitle" @click="showFolder"/>

</template>

<script>
import {dates} from '@/mixins/dates';
import {mapGetters} from 'vuex'

export default {
  name: 'FolderPromoPageLabel',
  mixins: [dates],
  props: {
    folderPage: {
      type: Object,
      default: ()=>{}
    },
  },
  computed: {
    ...mapGetters('auth', [
      'GUEST',
    ]),
    /**
     * Store title
     * @returns {*|string}
     */
    storeTitle() {
      return this.folderPage ? this.xsOnly ? this.folderPage.folder.store.translation.title: `Folder ${this.folderPage.folder.store.translation.title}` : ''
    },
  },
  methods: {
    showFolder(){

      const routeData = {
        name: 'folder.view',
        params: {
          storeSlug: this.folderPage.folder.store.translation.route,
          folderDate: this.dateStringFromISO(this.folderPage.folder.valid_from),
        },
        query: {
          id: this.folderPage.folder.id,
          page: this.folderPage.page || this.folderPage.order+1
        }
      }

      if (!this.GUEST){
        this.routerPush(routeData)
      } else {
        const href = this.$router.resolve(routeData).href;
        window.open(href, '_self');
      }

    },
  },
}
</script>

<style scoped>

</style>
