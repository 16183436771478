

<template>
  <div class="voting me-2">

    <span class="voting__value"  @click="xsOnly? $emit('like'):null">{{ post.rating_calculated }}</span>
    <div
        class="voting__block  me-2"
        :class="{'active': post.is_hit}"
        @click="$emit('like')"
    >
      {{$t('post.vote')}}
    </div>
  </div>
</template>

<script>
export default {
  name: 'Voting',
  emits: ['like'],
  props: {
    post: {
      type: Object,
      default: () => {},
      required: true
    }
  }
}
</script>
<style scoped>

</style>
