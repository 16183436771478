<template>
  <base-filter ref="filter" filter-key="reset_filter" must-have hide-divider>
    <template v-slot:header>
      <div class="filter-header bg-secondary--dark" @click="reset">
        <span class="filter-header__text" style="color: #3a00029e">{{ $t('filters.filters') }}:</span>
        <span class="filter-header__text--mini" style="color: #3a00029e" v-t="'filters.reset'"/>
      </div>
      <div class="px-1 py-2 filter-body">
        <div v-for="filter in appliedFilters" :key="filter.query"
             class="mb-2 d-flex justify-content-between align-items-center">
          <div class="filter-body__text">
            {{ filter.title }}
          </div>
          <span @click="removeQuery(filter.key, filter.query)" class="cursor-pointer filter-body__icon">
             <i class="trans-2 icon-close-4"/>
          </span>
        </div>
      </div>
    </template>
  </base-filter>
</template>

<script>
import BaseFilter from '@/components/left_bar/filters/BaseFilter';
import {mapActions, mapGetters} from 'vuex';
import router from '@/router';

export default {
  name: 'reset-filter',
  components: {BaseFilter},
  data() {
    return {
      promos_filters: {
        'day_deals': this.$t('post.promos_filter.day_deals'),
        'folder_promos': this.$t('post.promos_filter.folder_promos'),
        'promos': this.$t('post.promos_filter.promos'),
        'discount_code': this.$t('post.promos_filter.discount_code'),
        'post_cashback': this.$t('post.promos_filter.post_cashback'),
        'post_ecoupon': this.$t('post.promos_filter.post_ecoupon'),
        'post_free_products': this.$t('post.promos_filter.post_free_products'),
        'post_sale': this.$t('post.promos_filter.post_sale'),
      }
    }
  },
  computed: {
    ...mapGetters('promo', [
      'PROMOS_WEBSHOPS',
    ]),
    ...mapGetters('store', [
      'STORES',
    ]),
    ...mapGetters('promo', [
      'PROMOS_BRANDS',
    ]),
    ...mapGetters('filter', [
      'FILTERS_VALUES',
    ]),

    ...mapGetters('auth', [
      'GUEST',
    ]),

    appliedFilters() {

      const searchQuery = this.$route.query.q;
      const searchFilter = searchQuery ? {query: '', key: 'search_filter', title: searchQuery} : null

      const promosQuery = this.getQueryKeys('promos_filter[]');


      const promosFilters = promosQuery?.map(query => {
        return {
          query: query,
          key: 'promos_filter',
          title: this.promos_filters[query]
        }
      }) || []


      const webshopsQuery = this.getQueryKeys('webshops_filter[]');

      const webshopsFilters = webshopsQuery?.map(query => {
        return {
          query: query,
          key: 'webshops_filter',
          title: this.PROMOS_WEBSHOPS.find(webshop => +webshop.id === +query)?.translation?.title
        }
      }) || []

      const storesQuery = this.getQueryKeys('stores_filter[]');

      const storesFilters = storesQuery?.map(query => {
        return {
          query: query,
          key: 'stores_filter',
          title: this.STORES.find(store => +store.id === +query)?.translation?.title
        }
      }) || []

      const brandsQuery = this.getQueryKeys('brands_filter[]');

      const brandsFilters = brandsQuery?.map(query => {
        return {
          query: query,
          key: 'brands_filter',
          title: this.PROMOS_BRANDS.find(brand => +brand.id === +query)?.translation?.title
        }
      }) || []

      const priceQuery = this.getQueryKeys('price_filter[]');
      const priceFilter = priceQuery?.length ? {
        query: '',
        key: 'price_filter',
        title: this.$t('filters.price')
      } : null


      return [
        searchFilter,
        ...promosFilters,
        ...webshopsFilters,
        ...storesFilters,
        ...brandsFilters,
        priceFilter
      ].filter(Boolean);
    }

  },watch: {
    '$route.query': {
      handler(newValue, oldValue) {

        // Check if all properties in `newValue` have empty values
        const isEmptyQuery = Object.keys(newValue).every(
            (key) => Array.isArray(newValue[key]) ? !newValue[key].length : !newValue[key]
        );

        if (isEmptyQuery) {
          this.SET_FILTER_INACTIVE('reset_filter');
        }
      },
      deep: true,
    },
  },

  methods: {
    ...mapActions('filter', [
      'RESET_FILTERS',
      'SET_FILTER_VALUE',
      'SET_FILTER_INACTIVE',
    ]),


    reset() {
      if (this.$store.getters['auth/GUEST']) {
        window.open(this.$router.resolve({name: 'promos'}).href, '_self');
        return;
      }

      this.$router.replace({name: 'promos'}).then(() => {
        this.RESET_FILTERS(true)
        this.$emit('reset')
      })
    },
    getQueryKeys(key) {
      // Access the query object
      const query = this.$route.query;

      // Check if the key exists and return it as an array
      return query[key] ? (Array.isArray(query[key]) ? query[key] : [query[key]]) : [];
    },
    removeQuery(key, query) {
      if (key !== 'search_filter' && key !== 'price_filter') {
        const currentKeys = this.getQueryKeys(`${key}[]`)
        const newKeys = currentKeys.filter(item => item !== query);


        this.SET_FILTER_VALUE({[key]: newKeys})
      } else if (key === 'search_filter') {

        const query = _.clone(router.currentRoute.value.query);
        delete query.q;

        const r = router.replace({query: query})

        if (this.GUEST) {
          r.then(() => {
            window.location.reload()
          });
        }


      } else if (key === 'price_filter') {
        this.SET_FILTER_VALUE({[key]: []})
      }

    }

  }
}
</script>
