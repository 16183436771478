<template>
  <div class="d-flex align-items-center overflow-hidden" ref="container">
    <router-localized-link :to="routeBody">
      <div class="d-flex align-items-center">
        <el-avatar shape="circle" class="author-avatar">
          <img v-lazy="avatar" :alt="name"/>
        </el-avatar>

        <div class="author-info">
          <div class="author-name" :style="maxWidthStyle">{{ name }}</div>
          <div class="dot-separator">&#183;</div>
          <span
              ref="publicationTime"
              class="publication-time"
              :class="{'color-text-primary': isValid, 'color-primary': !isValid}"
          >
          {{ valid_text }}
        </span>
        </div>
      </div>
    </router-localized-link>
  </div>
</template>

<script>

import RouterLocalizedLink from '@/components/common/RouterLocalizedLink';
export default {
  name: 'AuthorInfo',
  components: {RouterLocalizedLink},
  props: {
    profileId: [Number, String],
    profileType: [String],
    avatar: {
      type: String,
    },
    name: {
      type: String,
    },
    valid_text: {
      type: String,
    },
    isValid: [String, Boolean],
  },
  data() {
    return {
      maxWidth: 0
    }
  },
  mounted() {
    if (this.$refs.container) {
      const other = this.xsOnly ? 30 : this.$refs.publicationTime.clientWidth + 64
      this.maxWidth = this.$refs.container.clientWidth - other
    }
  },
  computed: {
    maxWidthStyle() {
      return `max-width: ${this.maxWidth}px`
    },
    routeBody() {
      let routeBody = {name: 'home'};
      if (this.profileType === 'user') {
        routeBody = {name: 'profile', params: {userId: String(this.profileId)}};
      } else if (this.profileType === 'webshop') {
        routeBody = {name: 'webshop.show', params: {webshopSlug: this.profileId}};
      } else if (this.profileType === 'store') {
        routeBody = {name: 'folders.store', params: {storeSlug: this.profileId}};
      }
      return routeBody;
    }
  },
  methods: {
    goProfilePage() {
      //
    }
  }
}
</script>
