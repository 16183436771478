<template>
  <el-carousel :interval="interval">
    <el-carousel-item v-for="(ads, i) in modifiedAds" :key="i">
      <div class="w-100 h-100">
        <img v-lazy="ads.image_url" class="cursor-pointer" @click="goAd(ads.url)" :alt="ads.title">
      </div>
    </el-carousel-item>
  </el-carousel>
</template>

<script>
import {mapActions, mapGetters} from 'vuex';

export default {
  name: 'AdCarousel',
  props: {
    interval: {
      type: Number,
      default: 100000
    },
    adType: {
      type: String,
      default: ''
    }
  },
  mounted() {
    this.FETCH_ADS();
    // this.FETCH_ADS(`type=${this.adType}`);
  },
  computed: {
    ...mapGetters('ad', [
      'ADS',
    ]),
    modifiedAds() {
      return [...this.ADS].sort((a, b) => b.is_default - a.is_default);
    }
  },
  methods: {
    ...mapActions('ad', [
      'FETCH_ADS'
    ]),
    goAd(url) {
      window.open(url, '_blank');
    }
  }
}
</script>
