<template>
  <horizontal-promo-layout
    ref="layout"
    :promo="promo"
    :avatar="promo.user?.avatar_url ?? require('@/assets/images/fb_logo.png')"
    :name="promo.user?.full_name ?? 'Promojager'"
    :profile-id="promo.user?.id"
    :profileType="'user'"
    :has-promo-label="isBangPromo"
    morphable_type="Post"
    @like="makeHit"
    @favourite="makeFavourite"
  >
    <template #image>
      <div class="w-100 h-100 d-flex justify-content-center align-items-center" v-loading="imageIsLoading">
        <img
          class="covered lazy-load"
          v-lazy="imageLink"
          :data-src="imageLink"
          @click="showFullPromo"
          :data-style="JSON.stringify(imageStyles.lazyStyles)"
          :style="imageStyles.styles"
          @load="imageIsLoading=false"
          alt=""
        >
      </div>
    </template>
    <template #description>
      <div class="d-flex flex-column justify-content-center h-100" v-visible="fireViewEvent">
        <div class="d-flex flex-column">
          <span v-if="title" @click="showFullPromo" class="title " v-html="title"/>

          <div class="d-flex align-items-md-center align-items-start flex-md-row flex-column "  :class="[xsOnly? 'mb-1': 'mb-2']">
            <div class="price " v-if="promo.new_price || promo.discount || promo.discount_text">
              <template v-if="promo.new_price">
                <template v-if="promo.old_price">
                  <span class="price--old">€ {{ promo.old_price }}</span>
                  <span class="price--separator"> | </span>
                </template>
                <span class="price--new">€ {{ promo.new_price }}</span>
              </template>

              <span v-else-if="promo.discount" class="price--discount">{{ $t('post.discount') }}: {{
                  promo.discount
                }}</span>
              <span v-else-if="promo.discount_text" class="price--discount">{{ promo.discount_text }}</span>
            </div>
            <div class="d-flex justify-content-between align-items-center w-100 mt-1">
              <post-promo-webshop-label  :webshop="promo.webshop" :webshops="promo.webshops" :stores="promo.stores" />
              <div v-if="xsOnly" class="expiration-date">
                <span v-if="promo.is_expired" class="expired-promo">
                  {{ $t('post.expired.expired') }}
                </span>
                <span v-else-if="promo.valid_to_formatted">
                  {{ $t('promo.expires_at', {date: promo.valid_to_formatted}) }}
                </span>
              </div>
            </div>
          </div>

          <div>
            <button class="more" @click="showFullPromo">{{ $t('post.meer_detail') }}</button>
          </div>
        </div>

        <!--          promo type id ==5 means =>  Kortings code-->
        <copy-button-group
            v-if="promo.type.id===5"
            :promo-id="promo.id"
            :discount_code="promo.discount_code"
            :discount_codes_count="promo.discount_codes_count"
            :discount_url="promo.discount_url"
            :event-outgoing-click-meta="promo.event_outgoing_click_meta"
            copy-btn-class="look-promo"
            @opened="$emit('opened')">
        </copy-button-group>

        <button v-else class="look-promo" @click="btnClicked">
          {{(hasDiscountCode || promo.url)?  $t('post.go_webshop_btn') : $t('post.look_post')}}
        </button>

      </div>
    </template>
  </horizontal-promo-layout>
</template>

<script>
import post from '@/plugins/post';
import {needAuth} from '@/mixins/auth/needAuth';
import {actions} from '@/mixins/post/actions';
import {share} from '@/mixins/post/share';
import HorizontalPromoLayout from '@/components/feed/promo/desktop/HorizontalPromoLayout.vue';
import {discountCodes} from '@/mixins/post/discountCodes';
import CopyButtonGroup from '@/components/feed/buttons/CopyButtonGroup.vue';


export default {
  name: 'post-horizontal',
  components: {CopyButtonGroup, HorizontalPromoLayout},
  mixins: [actions, needAuth,discountCodes, share],
  props: {
    promo: Object,
    isBangPromo: {
      type: Boolean,
      default() {
        return false
      }
    },
    vuexModule: {
      type: String,
      default: 'promo',
    },
  },
  data() {
    return {
      imageLoadError: false,
      imageIsLoading: true,
    }
  },
  computed: {
    detections() {
      if (this.promo.images?.length) {
        return this.promo.images[0].detections;
      }
      return null;
    },

    imageStyles() {
      if (this.detections) {
        const clipPath = `polygon(${this.detections.polygon})`;
        const transform = `translate(${this.detections.transformX}%, ${this.detections.transformY}%)`;

        return {
         lazyStyles: {
           'clip-path': clipPath,
           height: 'auto',
         },
          styles: {
            width: `${this.detections.width}%`,
            'transform': transform,
          }
        };
      }
      return {};
    },
    imageLink() {
      if (this.promo.images.length) {
        return this.promo.images[0].src;
      }
      if (this.promo.webshop) {
        return this.promo.webshop.image_url;
      }
      return null;
    },
    /**
     * Data for mixin
     * @returns {{morphable_type: string, entity: Object}}
     */
    entityData() {
      return {
        entity: this.promo,
        morphable_type: 'Post'
      }
    },
    /**
     * Post title
     * @returns {*|string}
     */
    title() {
      return post.getTitle(this.promo);
    },
    hasDiscountCode() {
      let discountCode = 0;

      if (this.promo.discount_code || this.promo.discount_codes_count) {
        discountCode = 1;
      } else {
        if (_.get(this.promo, 'webshops[0].translation.affiliate_url', null)) {
          discountCode = 2;
        }
      }

      return discountCode;
    },

  },
  methods: {
    btnClicked() {
      if (this.hasDiscountCode) {
        this.openDiscount()
      } else if (this.promo.url) {
        window.open(this.promo.url, '_blank');
      } else {
        this.showFullPromo()
      }
    },
    showFullPromo() {
      this.$emit('opened')
      this.routerPush({name: 'posts.show', params: {id: String(this.promo.id)}});
    },
    /**
     * Favourite
     */
    makeFavourite() {
      this.processFavourite()?.then(()=>{
        this.$emit('favorite');
      })
    },
    /**
     * Like
     */
    makeHit() {
      const isHit = this.promo.is_hit;
      const thisElem = this

      this.processHit()?.then(() => {
        thisElem.$emit('voted')
      })
    },
    openDiscount() {
      if (this.hasDiscountCode === 1) {
        this.emitter.emit('copy-discount-code', {
          data: {discount_url: this.promo.discount_url, discount_code: this.promo.discount_code, id: this.promo.id},
          eventOutgoingClickMeta: this.promo.event_outgoing_click_meta
        });
        return;
      }
      if (this.hasDiscountCode === 2) {
        const resultUrl = this.promo.url !== null ? this.promo.url : _.get(this.promo, 'webshops[0].translation.affiliate_url', null);

        if (_.has(this.promo, 'event_outgoing_click_meta.event')) {
          const eventDataToPush = this.promo.event_outgoing_click_meta;
          eventDataToPush.user_id = _.get(this.USER, 'id', 0)
          eventDataToPush.clicked_link = resultUrl;
          eventDataToPush.clicked_text = 'button';

          this.processEventClick(eventDataToPush);
        }
        return window.open(resultUrl, '_blank');
      }

      this.axios.put('/posts/' + this.promo.id + '/discount-code').then((response) => {
        this.emitter.emit('copy-discount-code', {
          data: {
            discount_url: this.promo.discount_url,
            discount_code: response.data.data.discount_code,
            id: this.promo.id
          },
          eventOutgoingClickMeta: this.promo.event_outgoing_click_meta
        });
      }).catch((e) => {
        if (404 === e.response.status) {
          alert('No available codes');
        }
      });
    },
    fireViewEvent() {
      this.processEventView(this.preparePostViewEventData(this.promo));
    }
  }
}
</script>
