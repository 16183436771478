<template>
  <div class="article">
    <div class="article__header">
      <div class="favorite-circle">
        <i
          :class="{'active icon-heart-2': article.is_favourite, 'icon-heart': !article.is_favourite}"
          @click="processFavourite"
        />
      </div>
    </div>

    <a v-if="article.is_external" v-bind:href="article.translation.external_link">
      <div class="article__image">
        <img v-if="article.translation.cover_image" v-lazy="article.translation.cover_image" alt="">
        <img v-else src="@/assets/images/promo-blog.png" alt="" loading="lazy">
      </div>
      <div class="article__description" :class="textClass">
        <p>{{ article.translation.title }}</p>
      </div>
    </a>
    <router-localized-link v-else :to="articleRoute">
      <div class="article__image">
        <img v-if="article.translation.cover_image" v-lazy="article.translation.cover_image" alt="">
        <img v-else src="@/assets/images/promo-blog.png" alt="" loading="lazy">
      </div>
      <div class="article__description" :class="textClass">
        <p> {{ article.translation.title }} </p>
      </div>
    </router-localized-link>
  </div>
</template>

<script>
import RouterLocalizedLink from '@/components/common/RouterLocalizedLink.vue';

export default {
  name: 'Article',
  components: {RouterLocalizedLink},
  props: {
    article: Array,
    textClass: {
      type: String,
      default() {
        return 'py-2.25'
      }
    }
  },
  computed: {
    articleRoute() {
      return {
        name: 'news.article',
        params: {
          categorySlug: String(this.article.category.translation.slug),
          articleSlug: String(this.article.translation.slug),
        }
      };
    }
  },
  methods: {
    processFavourite() {
      if (this.article.is_favourite) {
        this.$store.dispatch('article/REMOVE_FAVORITE', this.article.id);
      } else {
        this.$store.dispatch('article/SET_FAVORITE', this.article.id)
      }
    }
  }
}
</script>
