<template>
  <div class="free-client-recommendations" ref="free_client_recommendations">
    <div class="centered">
      <h3 class="color-primary">
        <router-localized-link :to="{name: 'folders'}">
          <span>{{ $t('folder.pager.back_to_folders') }}</span>
        </router-localized-link>
      </h3>
    </div>

    <div class="flex-grow-1">
      <div v-if="CURRENT_STORE.last_folder.id !== CURRENT_FOLDER.id">
        <div class="folder-image-container cursor-pointer">
          <img v-lazy="CURRENT_STORE.last_folder.image_url"
              class="folder-image"
              :style="{'max-width': imageWidth + `px`}"
              @click="goFolder" alt="">
        </div>
        <div class="next-week-container">{{ $t('folder.pager.next_week') }}</div>
      </div>
      <div v-else>
        <store-mailer :dividers="false"
                      :show-client-recommendations-logo="true">
        </store-mailer>
      </div>
    </div>

    <div class="centered">
      <h3>
        <span>{{ $t('folder.pager.or_check_more_folder_current_store.1') }}</span><br/>
        <router-localized-link
          :to="{name: 'folders.store', params: {storeSlug: String(CURRENT_STORE.translation.route)}}"
        >
          &nbsp;<span>{{ $t('folder.pager.or_check_more_folder_current_store.2') }} 
            {{ $t('folder.pager.or_check_more_folder_current_store.3') }} {{ CURRENT_STORE.translation.title }}</span>
        </router-localized-link>
      </h3>
    </div>
  </div>
</template>

<script>
import RouterLocalizedLink from '@/components/common/RouterLocalizedLink';
import {randomSubscriptions} from '@/mixins/store/randomSubscriptions';
import {mapGetters} from 'vuex';

export default {
  name: 'semi-paid-client-recommendations',
  components: {
    RouterLocalizedLink,
  },
  mixins: [randomSubscriptions],
  data() {
    return {
      imageWidth: 0,
    }
  },
  computed: {
    ...mapGetters('store', [
      'CURRENT_STORE'
    ]),
    ...mapGetters('auth', [
      'GUEST',
    ]),
    ...mapGetters('folder', [
      'CURRENT_FOLDER',
    ]),
  },
  mounted() {
    const thisElem = this;
    new ResizeObserver(function () {
      thisElem.calculateHeight();
    }).observe(this.$refs.free_client_recommendations);
  },
  methods: {
    calculateHeight() {
      const thisElem = this;

      if (!thisElem.$refs.free_client_recommendations) {
        return 0;
      }
      thisElem.imageWidth = thisElem.$refs.free_client_recommendations.clientWidth - 200;
    },
    beforeSwitch() {
      return new Promise((resolve, reject) => {
        if (this.GUEST) {
          this.$dialogs.show('auth', 'default');
          return reject(new Error('Error'));
        }

        if (this.CURRENT_STORE.is_subscribed) {
          this.$store.dispatch('store/REMOVE_SUBSCRIPTION', this.CURRENT_STORE.id);
        } else {
          this.$store.dispatch('store/SET_SUBSCRIPTION', this.CURRENT_STORE.id);
        }

        return reject(new Error('Error'));
      })
    },

    goFolder() {
      this.routerPush({
        name: 'folder.view',
        params: {
          storeSlug: this.CURRENT_STORE.translation.route,
          folderDate: this.CURRENT_STORE.last_folder.valid_from
        },
        query: {
          id: this.CURRENT_STORE.last_folder.id
        }
      });
    },
  },
}
</script>
