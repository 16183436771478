<template>
  <el-dropdown class="reaction-dropdown" placement="bottom-end" trigger="click" popper-class="reaction-popper">
    <el-button class="share-btn" :class="btnClass">
      <i class="icon-share"/>
    </el-button>

    <template #dropdown>
      <el-dropdown-menu class="share-menu-dropdown">
        <el-dropdown-item @click="$emit('share', 'facebook')">
          <i class="icon-Facebook1 me-2"/>
          {{ $t('post.share.facebook') }}
        </el-dropdown-item>

        <el-divider class="my-1"/>
        <el-dropdown-item @click="$emit('share', 'messenger')">
          <i class="icon-Messenger me-2"/>
          {{ $t('post.share.messenger') }}
        </el-dropdown-item>

        <el-divider class="my-1"/>

        <el-dropdown-item @click="$emit('share', 'whats-app')">
          <i class="icon-WhatsApp me-2"/>
          {{ $t('post.share.whats_app') }}
        </el-dropdown-item>

        <el-divider class="my-1"/>

        <el-dropdown-item @click="$emit('share', 'email')">
          <i class="icon-envelope-o me-2"/>
          {{ $t('post.share.email') }}
        </el-dropdown-item>

        <el-divider class="my-1"/>

        <el-dropdown-item @click="copyLink">
          <i :class="`me-2 ${copiedLink ? 'icon-checkmark1' : 'icon-link'}`"/>
          {{ $t('post.share.copy_link') }}
        </el-dropdown-item>

        <template v-if="navigatorExists && xsOnly">
          <el-divider class="my-1"/>

          <el-dropdown-item @click="$emit('share', 'navigator')">
            <i class="icon-mail-forward me-2"/>
            {{ $t('post.share.more') }}
          </el-dropdown-item>
        </template>
      </el-dropdown-menu>
    </template>
  </el-dropdown>
</template>

<script>
export default {
  name: 'share',
  emits: ['share'],
  props: {
    btnClass: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      copiedLink: false
    }
  },
  computed: {
    navigatorExists() {
      return window.navigator && window.navigator.share;
    }
  },
  methods: {
    copyLink() {
      this.$emit('share', 'copy-link');
      this.copiedLink = true;
    }
  }
}
</script>
