<template>
  <div class="comment-wrapper">
    <div class="comment-wrapper__comment">
      <template v-if="comment.facebook_comment_id">
        <facebook-avatar :unique-id="comment.facebook_comment_id"/>

        <div class="comment-author">
          <div class="comment-author-name" v-text="comment.user.full_name"></div>
          <div class="comment-time" v-text="comment.time_ago"></div>
        </div>
      </template>
      <template v-else>
        <profile-preview-dialog :profile-id="comment.user.id">
          <el-avatar
              :src="comment.user.avatar_url"
              shape="circle"
              class="comment-author-avatar cursor-pointer"
              @mouseover="mouseHoverProfile = true"
              @mouseleave="mouseHoverProfile = false"
              loading="lazy"
          >
          </el-avatar>

          <div class="comment-author">
            <div
              class="comment-author-name cursor-pointer"
              :class="{'text-decoration-underline': mouseHoverProfile}"
              v-text="comment.user.full_name"
              @mouseover="mouseHoverProfile = true"
              @mouseleave="mouseHoverProfile = false"
            >
            </div>
            <div class="comment-time" v-text="comment.time_ago"></div>
          </div>
        </profile-preview-dialog>
      </template>

      <div class="w-100 d-flex flex-md-row flex-column justify-content-between ">
        <div class="comment-body clear-both pt-2">
          <dynamic-content :content="commentContent"></dynamic-content>
        </div>

        <div class="d-flex align-items-center mt-md-0 mt-4">
          <span class="mx-md-2 me-2 text-secondary" v-if="comment.hits.count > 0">{{ comment.hits.count }}</span>
          <div class="circle-wrapper me-3 cursor-pointer" :class="{'active': comment.is_hit}">
            <i class="icon-like-3 font-size-16" @click="tryHit"/>
          </div>
          <span class="cursor-pointer select-none font-weight-bold text-decoration-underline hover:color-primary"
                @click="showChildren">
            {{ $t('comment.reply_btn') }}
          </span>
        </div>
      </div>

      <div class="p-3" v-if="comment.image_url">
        <el-image
          style="width: 150px; height: 150px"
          :src="comment.image_url"
          :preview-src-list="[comment.image_url]"
          :initial-index="0"
          fit="cover"/>
      </div>
    </div>

    <div class="mt-2 ms-2" v-if="showReply || childrenCount > 0">
      <div class="d-flex align-items-center justify-content-between font-size-15" v-if="childrenCount > 0">
        <span
          class="cursor-pointer text-secondary text-decoration-underline hover:color-primary select-none"
          @click="showChildren"
        >
          <i class="font-size-16"
             :class="{'icon-arrow-down-right': !isVisibleChildren, 'icon-arrow-up-left': isVisibleChildren}">
          </i>
          {{ $t('comment.show_replies_btn', {total: childrenCount}) }}
        </span>

      </div>

      <div class="mt-2 ms-4" v-if="isVisibleChildren">
        <comment
          v-for="entry in children"
          :comment="entry"
          :key="entry.id"
          :show-reply="false"
          @hit="hitComment(entry.id)"
        />
        <comments-loading :loading="childrenLoading"/>
        <comment-form @submit="pushComment"/>
      </div>
    </div>
  </div>
</template>

<script>
import CommentForm from '@/components/feed/comment/CommentForm';
import CommentsLoading from '@/components/feed/comment/CommentsLoading';
import {needAuth} from '@/mixins/auth/needAuth';
import FacebookAvatar from '@/components/feed/comment/FacebookAvatar';
import DynamicContent from '@/components/feed/post/DynamicContent';
import ProfilePreviewDialog from '@/components/feed/profile/ProfilePreviewDialog';

export default {
  name: 'comment',
  components: {ProfilePreviewDialog, DynamicContent, FacebookAvatar, CommentsLoading, CommentForm},
  mixins: [needAuth],
  emits: ['hit'],
  props: {
    comment: {
      type: Object,
    },
    showReply: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    commentContent() {
      if (this.comment.content.startsWith('#')) {
        return ' ' + this.comment.content;
      } else {
        return this.comment.content;
      }
    }
  },
  data() {
    return {
      childrenCount: 0,
      children: [],

      childrenLoading: false,
      isVisibleChildren: false,
      isLiked: false,
      mouseHoverProfile: false,
    }
  },
  mounted() {
    this.childrenCount = this.comment.children_count;
  },
  methods: {
    showChildren() {
      if (this.triggerLoginDialog()) {
        return;
      }
      this.isVisibleChildren = !this.isVisibleChildren;
      if (!this.isVisibleChildren) {
        return;
      }
      this.loadChildren()
    },
    loadChildren() {
      this.childrenLoading = true;
      axios.get('/comments/' + this.comment.id + '/children').then((response) => {
        this.childrenLoading = false;
        this.children = response.data.data;
        this.childrenCount = this.children.length;
      });
    },
    pushComment(form) {
      this.childrenLoading = true;
      return axios.post(`/comments/${this.comment.id}`, form).then((response) => {
        this.childrenLoading = false;
        this.children = response.data.data;
        this.childrenCount = this.children.length;
      });
    },
    tryHit() {
      if (this.triggerLoginDialog()) {
        return;
      }
      this.$emit('hit');
    },
    hitComment(commentId) {
      return axios.post(`/comments/${commentId}/hit`).then((response) => {
        this.children = response.data.data;
        this.childrenCount = this.children.length;
      });
    }
  }
}
</script>
