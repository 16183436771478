<template>
  <div v-loading.fullscreen.lock="IS_LOADING"></div>
  <locale-switcher-dialog></locale-switcher-dialog>
</template>

<script>

import {discountCodes} from '@/mixins/post/discountCodes';
import {folders} from '@/mixins/public_spa_js/folders';
import {posts} from '@/mixins/public_spa_js/posts';
import {header} from '@/mixins/public_spa_js/header';
import {mapGetters} from 'vuex';
import LocaleSwitcherDialog from '@/components/header/LocaleSwitcherDialog';

export default {
  name: 'min-app',
  components: { LocaleSwitcherDialog},
  mixins: [discountCodes, folders, posts, header],
  computed: {
    ...mapGetters('loader', [
      'IS_LOADING',
    ]),
  },
  beforeMount() {
    this.$store.dispatch('auth/UPDATE_I18N', this.$i18n);
  },
  watch: {
    $i18n: {
      handler(val) {
        this.$store.dispatch('auth/UPDATE_I18N', this.$i18n);
      },
      deep: true
    }
  }
}
</script>
