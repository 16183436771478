

<template>
  <div class="reactions-wrapper">
    <div class="reaction-info">
      <div class="d-flex align-items-center">
        {{ rating }} {{$t('post.votes')}}
      </div>
      <div>
        {{
          commentsCount === 1 ? $t('comment.one_comment', {count: commentsCount}) : $t('comment.total_comments', {count: commentsCount})
        }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ReactionsInfo',
  props: {
    rating: Number,
    commentsCount: Number,
  },
}
</script>

<style scoped>

</style>
