<template>
    <TransitionGroup tag="div" name="fade" class=" container promos-grid" v-if="currentTabContent.length">

    <div v-for="(promo, i) in currentTabContent" :key="i">
        <component
            :is="getComponentName(promo.morphable_type)"
            :promo="promo.data"
            :show-promoman="isPromomanVisible(i)"
            @opened="saveScrollPosition(i)"
        />

    </div>
    </TransitionGroup>
</template>

<script>
import FolderPromoHorizontalDesktop from '@/components/feed/promo/desktop/FolderPromoHorizontalDesktop.vue';
import PostHorizontalDesktop from '@/components/feed/promo/desktop/PostHorizontalDesktop.vue';
import OnlinePromoHorizontalDesktop from '@/components/feed/promo/desktop/OnlinePromoHorizontalDesktop.vue';
import {breakpoint} from '@/mixins/public_spa_js/breakpoint';

export default {
  name: 'search-promos-list',
  components: {
    OnlinePromoHorizontalDesktop,
    PostHorizontalDesktop,
    FolderPromoHorizontalDesktop,

  },
  props: {
    items: Array,
    currentTab: String,
    vuexModules: {
      type: Object,
      default() {
        return {
          'post': 'promo',
          'online_promo': 'promo',
          'folder_promo': 'promo',
          'discount-codes': 'promo',
        }
      }
    },
  },
  computed: {
    currentTabContent() {
      switch (this.currentTab) {
        case 'default':
          return this.items;
        case 'posts':
          return this.items.filter(promo => promo.morphable_type === 'Post');
        case 'folder-promos':
          return this.items.filter(promo => promo.morphable_type === 'FolderItem');
        case 'online-promos':
          return this.items.filter(promo => promo.morphable_type === 'OnlinePromo');
        case 'favourites':
          return this.items; // Show all types for favourites
        case 'discount-codes':
          return this.items.filter(promo => promo.morphable_type === 'Post');
        default:
          return [];
      }
    },
  },
  methods: {

    saveScrollPosition(key) {
      breakpoint.set(this.$router.currentRoute.value.fullPath, {pos: window.scrollY, key})
    },
    /**
     * Gets the component name based on the morphable type.
     * If the current tab is 'favourites', it removes the '-mini' suffix for the component.
     * @param {string} morphableType - The type of the promo (e.g., 'Post', 'OnlinePromo', 'FolderItem').
     * @returns {string} - The name of the component to render.
     */
    getComponentName(morphableType) {
      const componentMap = {
        Post: 'post-horizontal-desktop',
        OnlinePromo: 'online-promo-horizontal-desktop',
        FolderItem: 'folder-promo-horizontal-desktop',
      };
      return componentMap[morphableType];
    },
    /**
     * Is promoman visible
     * @param i
     * @returns {boolean|boolean|*}
     */
    isPromomanVisible(i) {
      return (!this.mdAndUp && !this.xsOnly && i === 1)
          || (this.mdAndUp && i === 2)
    },
  },
}
</script>
