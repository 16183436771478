<template>
  <button class="w-100 new-copy-code"
          :class="btnClass"
          type="primary"
          @click="buttonClicked" >
    {{ $t('post.discount_btn') }}
  </button>
</template>

<script>

import {discountCodes} from '@/mixins/post/discountCodes';

export default {
  name: 'copy-code-button',
  mixins: [discountCodes],
  props: {
    id: {
      type: Number
    },
    url: {
      type: String
    },
    code: {
      type: String,
      default: ''
    },
    btnClass: {
      type: String,
      default: ''
    },
    eventOutgoingClickMeta: {
      type: Object,
      default() {
        return {}
      }
    }
  },
  methods: {
    buttonClicked() {
      console.log('clicked', this.code, this.code.length)
      if (this.code && this.code.length) {
        this.emitter.emit('copy-discount-code', {
          data: {discount_url: this.url, discount_code: this.code, id: this.id},
          eventOutgoingClickMeta: this.eventOutgoingClickMeta
        });
        return;
      }

      // request unique discount code
      this.axios.put('/posts/' + this.id + '/discount-code').then((response) => {
        this.emitter.emit('copy-discount-code', {
          data: {discount_url: this.url, discount_code: response.data.data.discount_code, id: this.id},
          eventOutgoingClickMeta: this.eventOutgoingClickMeta
        });
      }).catch((e) => {
        if (404 === e.response.status) {
          alert('No available codes');
        }
      });
    }
  }
}
</script>
