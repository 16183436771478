import router from '@/router/index';

export default {
    mounted(el, binding) {
        const observer = new IntersectionObserver(
            (entries) => {
                entries.forEach((entry) => {
                    if (entry.isIntersecting) {
                        // Call the provided function
                        if (typeof binding.value === 'function') {

                            // Functions executed by this v-visible directive. Needs loaded router
                            router.isReady().then(() => {
                                binding.value(entry);
                            })
                        }
                        observer.disconnect(); // Stop observing once visible
                    }
                });
            },
            { threshold: 0.1 } // Adjust visibility threshold as needed
        );

        observer.observe(el); // Start observing the element
    },
};
