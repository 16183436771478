<template>
  <el-form
    ref="form"
    :model="form"
    class="post-step__container optional-step"
  >
    <div class="post-step__title el-input el-input--default is-disabled">
      <div class="el-input__wrapper">
        <div class="el-input__inner" v-html="titlePlaceholder"/>
        <span class="font-size-18 font-weight-450">(+2&nbsp;</span>
        <div class="coins pt-1">
          <img style="height: 15px" src="@/assets/images/coins.png" alt="" loading="lazy">
        </div>
        <span class="font-size-18 font-weight-450">)</span>
      </div>
    </div>

    <!--
      Prices
    -->
    <el-row v-if="showPrices" justify="space-between" align="middle" class="optional-step__prices">
      <div class="d-inline-flex align-items-center" :class="{'w-100': xsOnly}">
        <el-checkbox
          v-if="showPriceCheckbox"
          v-model="form.only_price"
          class="me-2 mb-2"
          size="large"
          @click="checkboxEvent('price')"
        />
        <el-form-item
          prop="old_price"
          :class="{'label-disabled': priceDisabled}"
          :label="$t('post.old_price')"
          :rules="rules.old_price"
        >
          <el-input
            v-model="form.old_price"
            type="number"
            :disabled="priceDisabled"
            size="small"
          >
            <template #append>&euro;</template>
          </el-input>
        </el-form-item>
      </div>

      <el-form-item
        prop="new_price"
        class="new-price"
        :class="{'label-disabled': priceDisabled}"
        :label="$t('post.new_price')"
        :rules="rules.new_price"
      >
        <el-input
          v-model="form.new_price"
          type="number"
          :disabled="priceDisabled"
          size="small"
        >
          <template #append>&euro;</template>
        </el-input>
      </el-form-item>
    </el-row>

    <!--
      Discount
    -->
    <el-row v-if="showDiscount" justify="space-between" align="middle" class="optional-step__discount">
      <div class="d-inline-flex align-items-center" :class="{'w-100': xsOnly}">
        <el-checkbox
          v-if="showDiscountCheckbox"
          v-model="form.only_discount"
          class="me-2"
          size="large"
          @click="checkboxEvent('discount')"
        />
        <el-form-item
          prop="discount_value"
          :class="{'label-disabled': discountDisabled}"
          :label="$t('post.discount')"
          :rules="rules.discount_value"
        >
          <el-input
            v-model.number="form.discount_value"
            :disabled="discountDisabled"
            type="number"
            size="small"
          >
            <template #append>
              <el-select v-model="form.discount_type">
                <el-option label="%" value="percent"/>
                <el-option label="&euro;" value="euro"/>
              </el-select>
            </template>
          </el-input>
        </el-form-item>
      </div>
    </el-row>

    <!--
      Another type
    -->
    <div v-if="showAnotherType" class="optional-step__another-type mt-1">
      <el-checkbox
        v-model="form.only_another_type"
        class="me-2"
        size="large"
        @click="checkboxEvent('another_type')"
      />
      <el-form-item
        prop="another_type"
        ref="anotherTypeFormItem"
        :class="{'label-disabled': anotherTypeDisabled}"
        :label="$t('post.another_type')"
        :rules="rules.another_type"
      >
        <el-input
          v-model="form.another_type"
          :disabled="anotherTypeDisabled"
          :placeholder="$t('post.placeholders.another_type')"
          size="small"
        />
      </el-form-item>
    </div>

    <el-divider v-if="showPrices || showDiscount || showAnotherType" class="mb-3 mt-2"/>

    <!--
      Dates
    -->
    <el-row justify="space-between" align="middle" class="optional-step__daterange">
      <el-form-item :label="$t('post.start_date')" prop="started_at" :rules="rules.started_at">
        <el-date-picker
          v-model="form.started_at"
          :disabled="startedAtDisabled"
          placeholder="dd/mm/jj"
          type="date"
          size="small"
        />
      </el-form-item>

      <el-form-item :label="$t('post.end_date')" prop="expired_at" :rules="rules.expired_at">
        <el-date-picker
          v-model="form.expired_at"
          :disabled="isDiscount"
          :disabled-date="disableExpiredAtDates"
          placeholder="dd/mm/jj"
          type="date"
          size="small"
        />
      </el-form-item>
    </el-row>

    <!--
        Free shipping
    -->
    <template v-if="showFreeShipping">
      <el-divider class="mb-3 mt-2"/>

        <el-form-item prop="shipping_number" class="optional-step__free-shipping" :rules="rules.shipping_number">
          <div class="d-inline-flex justify-content-start w-100" :class="{'flex-column': xsOnly}">
            <el-checkbox v-model="form.free_shipping" :label="`${$t('post.free_shipping')}?`" class='pe-2.25'/>
            <el-input
                v-model="form.shipping_number"
                type="number"
                :placeholder="$t('post.placeholders.free_shipping')"
                :class="{'d-none': !form.free_shipping}"
                size="small"
            >
              <template #append>&euro;</template>
            </el-input>
          </div>
        </el-form-item>
    </template>
  </el-form>
</template>

<script>
import {mapGetters, mapState} from 'vuex';
import {step} from '@/mixins/post/step';

export default {
  name: 'OptionalContentStep',
  mixins: [step],
  computed: {
    ...mapGetters('auth', ['USER']),
    ...mapGetters('bonus_action', [
      'BONUS_ACTIONS',
      'BONUS_ACTION_TYPES',
    ]),
    ...mapState('promo', {
      form: ({form}) => form,
      subtype: ({form}) => form.subtype,
      started_at: ({form}) => form.started_at,
      expired_at: ({form}) => form.expired_at
    }),
    /**
     * Title placeholder
     * @returns {string}
     */
    titlePlaceholder() {
      return `${this.titleNumber}. ${this.$t('post.steps.optional_content')}`
    },
    /**
     * Is discount
     * @returns {boolean}
     */
    isDiscount() {
      return this.$consts.KIND.DISCOUNT === _.get(this.subtype, 'kind', null);
    },
    /**
     * Is Sale
     * @returns {boolean}
     */
    isSale() {
      return this.subtype.id === this.$consts.POST_TYPES.SALE
    },
    /**
     * Is Cashback
     * @returns {boolean}
     */
    isCashback() {
      return this.subtype.id === this.$consts.POST_TYPES.CASHBACK
    },
    /**
     * Is e-coupon
     * @returns {boolean}
     */
    isEcoupon() {
      return this.subtype.id === this.$consts.POST_TYPES.ECOUPON
    },
    /**
     * Is free products
     * @returns {boolean}
     */
    isFreeProducts() {
      return this.subtype.id === this.$consts.POST_TYPES.FREE_PRODUCTS
    },
    /**
     * Are price fields disabled
     * @returns {boolean}
     */
    priceDisabled() {
      return this.form.only_another_type
        || !!this.form.another_type
        || this.form.only_discount
        || !!this.form.discount_value
    },
    /**
     * Is discount field disabled
     * @returns {boolean}
     */
    discountDisabled() {
      return this.form.only_another_type
        || !!this.form.another_type
        || this.form.only_price
        || !!this.form.new_price
        || !!this.form.old_price
    },
    /**
     * Is Another Type checkbox disabled
     * @returns {boolean}
     */
    anotherTypeDisabled() {
      return this.form.only_discount
        || !!this.form.discount_value
        || this.form.only_price
        || !!this.form.new_price
        || !!this.form.old_price
    },
    /**
     * Is started_at disabled for edit
     * @returns {false|boolean|*}
     */
    startedAtDisabled() {
      return this.isDiscount && this.form.not_started && this.extra.startedAtDisabled
    },
    /**
     * Show checkbox next to price
     * @returns {boolean}
     */
    showPriceCheckbox() {
      return this.showDiscount || this.showAnotherType
    },
    /**
     * Show checkbox next to discount
     * @returns {boolean}
     */
    showDiscountCheckbox() {
      return this.showPrices || this.showAnotherType
    },
    /**
     * Show Prices section
     * @returns {boolean}
     */
    showPrices() {
      return !this.isDiscount && !this.isSale && !this.isEcoupon && !this.isFreeProducts && !this.isCashback
    },
    /**
     * Show Discount section
     * @returns {boolean}
     */
    showDiscount() {
      return !this.isSale && !this.isFreeProducts && !this.isCashback
    },
    /**
     * Show Another type section
     * @returns {boolean}
     */
    showAnotherType() {
      return !this.isDiscount && !this.isSale && !this.isEcoupon && !this.isFreeProducts && !this.isCashback
    },
    /**
     * Show Free Shipping section
     * @returns {boolean}
     */
    showFreeShipping() {
      return !this.isSale && !this.isFreeProducts && !this.isCashback
    }
  },
  data() {
    return {
      rules: {
        old_price: [{validator: this.floatValidator}],
        new_price: [{validator: this.floatValidator}],
        discount_value: [{validator: this.floatValidator}],
        another_type: [{validator: this.floatValidator, type: 'string', message: this.$t('post.errors.format')}],
        started_at: [{validator: this.dateValidator}],
        expired_at: [{validator: this.dateValidator}],
        shipping_number: [{validator: this.floatValidator}],
      },
      extra: {
        startedAtDisabled: true,
      },
    }
  },
  methods: {
    /**
     * @param time
     * @returns {boolean}
     */
    disableExpiredAtDates(time) {
      const now = Date.now()
      const startedAt = new Date(this.started_at)

      return startedAt.getTime() > Date.now()
        ? time.getTime() < startedAt
        : time.getTime() < now
    },
    /**
     * Float validator
     * @param rule
     * @param value
     * @param cb
     */
    floatValidator(rule, value, cb) {
      if (rule.field === 'another_type') {
        this.form.another_type = value.substring(0, 22);
      } else {
        if (!value) {
          cb();
        }

        if (isNaN(value) || value <= 0 || value > 999999) {
          cb(this.$t('post.errors.format'));
        }

        cb()
      }

      let flag = true;
      if (value === '' && (rule.field === 'discount_value' || rule.field === 'another_type')) {
        flag = false;
      }

      const strlen = String(this.form.new_price + this.form.old_price).length;
      if ((rule.field === 'old_price' || rule.field === 'new_price') && strlen === 0) {
        flag = false;
      }
      switch (rule.field) {
        case 'old_price':
        case 'new_price':
          this.updateForm({only_price: flag})
          break
        case 'discount_value':
          this.updateForm({only_discount: flag})
          break
        case 'another_type':
          this.updateForm({only_another_type: flag})
          break
      }
      cb()
    },
    /**
     * Date validator
     * @param rule
     * @param value
     * @param cb
     */
    dateValidator(rule, value, cb) {
      if (!value) {
        cb();
      }

      if (!(value instanceof Date)) {
        cb(this.$t('post.errors.format'));
      }

      if (
        this.expired_at &&
        this.started_at &&
        this.started_at.getTime() > this.expired_at.getTime()
      ) {
        cb(this.$t('post.errors.date_range'));
      }

      cb()
    },
    /**
     * @param checkbox
     */
    checkboxEvent(checkbox) {
      switch (checkbox) {
        case 'price':
          this.updateForm({only_discount: false})
          this.updateForm({only_another_type: false})
          this.form.discount_value = '';
          this.form.another_type = '';
          break
        case 'discount':
          this.updateForm({only_price: false})
          this.updateForm({only_another_type: false})
          this.form.old_price = '';
          this.form.new_price = '';
          this.form.another_type = '';
          break
        case 'another_type':
          this.updateForm({only_price: false})
          this.updateForm({only_discount: false})
          this.form.old_price = '';
          this.form.new_price = '';
          this.form.discount_value = '';
          break
      }
    },
    /**
     * Validation
     * @returns {boolean}
     */
    async validate() {
      let result = false;
      await this.$refs.form.validate((isValid) => result = isValid)

      this.$emit('update', {titlesCount: 1})

      return result
    }
  },
  watch: {
    started_at(newVal) {
      if (newVal && this.isDiscount && new Date(newVal) > Date.now()) {
        this.updateForm({not_started: true})
        this.extra.startedAtDisabled = false
      }
    }
  }
}
</script>
