<template>
  <el-dropdown
      placement="bottom-end" class="report-dropdown" trigger="click" popper-class="report-dropdown__popper"
      :disabled="promo.is_expired"
      ref="report-dropdown"
      @visible-change="handleReport"

  >
    <div class="d-flex flex-column align-items-center expire-btn"
         :class="{'reported': promo.is_reported_expired, 'expired': promo.is_expired}">
      <img class="img-reported" src="@/assets/images/expired-orange.svg" alt="Expired Icon" loading="lazy">
      <img class="img-expired" src="@/assets/images/expired.svg" alt="Expired Icon" loading="lazy">
      <div v-if="promo.is_expired">{{ $t('post.expired.expired') }}</div>
      <div v-else>{{
          promo.is_reported_expired ? $t('post.expired.reported') : `${$t('post.expired.mark_expired')}`
        }}
      </div>
    </div>
    <template #dropdown>
      <el-dropdown-menu>
        <div class="report-dropdown__header" v-html="$t('post.expired.thanks')" />
      </el-dropdown-menu>
    </template>
  </el-dropdown>
</template>
<script>
import {needAuth} from '@/mixins/auth/needAuth';
import {mapActions, mapGetters, mapMutations} from 'vuex';
import {ElMessageBox} from 'element-plus'

export default {
  name: 'ReportExpiredPost',
  props: {
    morphable_type: {
      type: String,
      required: true,
    },
    promo: {
      type: Object,
      required: true,
      default: () => {}
    },
  },
  mixins: [needAuth],
  data() {
    return {
      dialogConfirm: true,
      textFieldVisible: false,
      code: ''

    }
  },
  computed: {
    ...mapGetters('auth', [
      'GUEST',
      'USER',
    ]),
  },
  methods: {
    ...mapActions('promo', [
      'REPORT_EXPIRED_PROMO',
    ]),
    ...mapMutations('promo', [
      'UPDATE_PROMO',
    ]),

    handleReport(visible) {
      if (!visible || this.promo.is_reported_expired || this.promo.is_expired) return
      if (this.GUEST){
        this.$refs['report-dropdown'].handleClose()
      }
      if (this.triggerLoginDialog()) {
        return;
      }
      if (this.USER?.is_moderator) {
        this.$refs['report-dropdown'].handleClose()
        this.confirmReport()
      } else {
        this.reportExpired()
      }
    },
    /**
     * Confirm report for moderators
     */
    confirmReport() {
      ElMessageBox.confirm(
          this.$t('post.expired.sure_report_expired'),
          '',
          {
            confirmButtonText: this.$t('post.expired.yes'),
            cancelButtonText: this.$t('post.expired.cancel'),
            type: 'warning',
            customClass: 'message-box-report-expired'
          }
      ).then(() => {
        this.reportExpired()
          })
    },
    /**
     * Toggle Promo Expired
     */
    async reportExpired() {

      await this.REPORT_EXPIRED_PROMO({
        reportable_id: this.promo.id,
        reportable_type: this.morphable_type
      })
    }
  }
}
</script>

<style scoped>

</style>
