<template>
  <div class="folder-promo-mini" v-visible="fireViewEvent">
    <div v-if="showPromoman" class="promo-hunter">
      <img src="@/assets/images/promo_hunter.svg" class="promo-hunter__face w-100 h-100" alt=""  loading="lazy">
      <img src="@/assets/images/promo_hunter_hands.svg" class="promo-hunter__hands" alt=""  loading="lazy">
    </div>
    <div
      v-if="isBangPromo"
      class="bang-promo"
      @click="showFullPromo"
      @mouseenter="bangPromoHover = true"
      @mouseleave="bangPromoHover = false"
    />
    <div v-if="upvoteAnimation" class="upvote-animation">
      <img src="@/assets/images/firework.gif" class="w-100 h-100" alt=""  loading="lazy">
    </div>

    <!--
       Header
    -->
    <div class="folder-promo-mini__header">
      <div class="favorite-circle">
        <i
          :class="{'active icon-heart-2': folderPromo.is_favourite, 'icon-heart': !folderPromo.is_favourite}"
          @click="processFavourite"
        />
      </div>
    </div>

    <!--
       Image
    -->
    <div class="folder-promo-mini__image" >
      <img
        v-lazy="folderPromo.image_url"
        :class="{'hover': bangPromoHover}"
        @error="imageLoadError = true"
        @click="showFullPromo"
        alt=""
      >
    </div>

    <!--
       Content
    -->
    <div class="folder-promo-mini__content">
      <div class="price" v-if="folderPromo.new_price || folderPromo.discount">
        <span class="me-1">&#8212;</span>

        <template v-if="folderPromo.new_price">
          <template v-if="folderPromo.old_price">
            <span class="price--old">€ {{ folderPromo.old_price }}</span>
            <span class="price--separator"> | </span>
          </template>
          <span class="price--new">€ {{ folderPromo.new_price }}</span>
        </template>

        <span v-else-if="folderPromo.discount" class="price--discount">
          {{ $t('post.discount') }}: {{ folderPromo.discount }}
        </span>

        <span class="ms-1">&#8212;</span>
      </div>

      <div class="px-md-2 d-flex flex-column align-items-center">
        <p class="title cursor-pointer" v-text="folderPromo.title" @click="showFullPromo"/>
        <button class="more" @click="showFullPromo">{{ $t('post.meer_detail') }}</button>
      </div>

      <div class="w-100 d-flex justify-content-center align-items-center">
        <button class="look-promo mx-md-1 w-100" @click="goFolder">
          {{ $t('post.review_folder') }}
        </button>
      </div>

      <div class="w-100 d-flex justify-content-center align-items-center">
        <div class="author-info-container">
          <div class="w-100 d-flex justify-content-between">
            <author-info
              class="flex-grow-1 justify-content-start justify-content-md-center"
              :avatar="authorAvatar"
              :name="authorName"
              :profile-id="profileId"
              :profileType="profileType"
              :valid_text="folderPromo.valid_text"
              :is-valid="!folderPromo.is_expired"
            />
          </div>
        </div>
      </div>
    </div>

    <!--
      Footer
    -->
    <div class="folder-promo-mini__footer">
      <votes
          :is-like="folderPromo.is_hit"
          :is-dislike="folderPromo.is_dislike"
          :rating="folderPromo.rating_calculated"
          @like="makeHit"
          @dislike="makeDislike"
      />
    </div>
  </div>
</template>

<script>
import {needAuth} from '@/mixins/auth/needAuth';
import {actions} from '@/mixins/post/actions';
import Votes from '@/components/feed/Votes.vue';

export default {
  name: 'folder-promo-mini',
  components: {Votes},
  mixins: [actions, needAuth],
  props: {
    folderPromo: Object,
    isBangPromo: {
      type: Boolean,
      default: false
    },
    showPromoman: {
      type: Boolean,
      default: false
    },
    vuexModule: {
      type: String,
      default: 'promo',
    },
  },
  data() {
    return {
      imageLoadError: false,
      bangPromoHover: false,
      upvoteAnimation: false,
    }
  },
  computed: {
    /**
     * @returns {any}
     */
    editor() {
      return this.folderPromo.editor;
    },
    /**
     * Avatar url
     * @returns {*}
     */
    authorAvatar() {
      return !this.editor ? this.folderPromo.folderPage.folder.store.image_url : this.editor.avatar_url
    },
    /**
     * Author name
     * @returns {*|string}
     */
    authorName() {
      return !this.editor ? this.storeTitle : this.editor.full_name
    },
    profileId() {
      return !this.editor ? this.folderPromo.folderPage.folder.store.translation.route : String(this.editor.id)
    },
    profileType() {
      return !this.editor ? 'store' : 'user'
    },
    /**
     * Data for mixin
     * @returns {{morphable_type: string, entity: Object}}
     */
    entityData() {
      return {
        entity: this.folderPromo,
        morphable_type: 'FolderItem'
      }
    },
    /**
     * Store title
     * @returns {*|string}
     */
    storeTitle() {
      const folderPage = this.folderPromo.folderPage;

      return folderPage ? folderPage.folder.store.translation.title : ''
    }
  },
  mounted() {
    this.emitter.on('show-folder-promo-preview', (id) => {
      if (this.folderPromo.id === id) {
        this.showFullPromo();
      }
    });
  },
  methods: {
    /**
     * Share
     * @param destination
     */
    processShare(destination) {
      const link = new URL(
          this.$router.resolve({
            name: 'folder_promos.show',
            params: {id: this.folderPromo.id}
          }).href,
          window.location.origin
      ).href;
      this.shareLink(link, destination);
    },
    /**
     * Like
     */
    makeHit() {
      const isHit = this.folderPromo.is_hit;
      const thisElem = this

      this.processHit()?.then(() => {
        thisElem.$emit('voted')

        setTimeout(() => {
          if (!isHit && thisElem.isBangPromo) {
            thisElem.enableUpvoteAnimation()
          }
        }, 200);
      })
    },
    /**
     * Dislike
     */
    makeDislike() {
      const thisElem = this

      this.processDislike()?.then(function () {
        thisElem.$emit('voted')
      })
    },
    enableUpvoteAnimation() {
      this.upvoteAnimation = true;
      setTimeout(() => this.upvoteAnimation = false, 4200)
    },
    showFullPromo() {
      this.$emit('opened')
      this.routerPush({name: 'folder_promos.show', params: {id: String(this.folderPromo.id)}});
    },
    goFolder() {
      this.routerPush({
        name: 'folder.view',
        params: {
          storeSlug: this.folderPromo.folderPage.folder.store.translation.route,
          folderDate: this.folderPromo.folderPage.folder.valid_from,
        },
        query: {
          id: this.folderPromo.folderPage.folder.id,
          page: this.folderPromo.folderPage.page
        }
      });
      this.$emit('folder-link-clicked');
    },
    fireViewEvent() {
      this.processEventView(this.prepareFolderPromoViewEventData(this.folderPromo));
    }
  }
}
</script>
