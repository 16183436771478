<template>
  <div v-if="showBackButton" @click="goBack" class="back-button">
    <i class="icon-arrow-4 icon-flipped"/>
    <el-divider direction="vertical" class="h-100"/>
  </div>
</template>
<script>
import {mapGetters} from 'vuex';
export default {
  name: 'BackButton',
  computed: {
    ...mapGetters('auth', [
      'GUEST',
    ]),
    /**
     * @returns {boolean|*|boolean}
     */
    showBackButton() {
      if (this.GUEST && typeof window !== 'undefined') {
        return (
            this.xsOnly &&
            window.location.pathname !== '/' &&
            window.history.length > 2
        );
      }
      if (typeof window !== 'undefined' && window.history && window.history.state) {
        return (
            this.xsOnly &&
            this.$route.name !== 'home' &&
            !!window.history.state.back
        );
      }
      return false;
    }
  },
  methods: {
    goBack() {
      if (this.GUEST){
        window.history.back();
      }else {
        this.$router.go(-1)
      }
    }
  },
}
</script>
<style scoped>
</style>
