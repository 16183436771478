<template>
  <el-form
    ref="form"
    :model="form"
    class="post-step__container quote-step"
  >
    <el-input
      class="post-step__title"
      :placeholder="`${titleNumber}. ${$t('post.steps.quote')}`"
      disabled
    />

    <el-form-item prop="content" :rules="rules.quote" class="position-relative">
      <div class="d-flex w-100">
        <el-avatar class=" mt-3" :src="USER.avatar_url" shape="circle"/>
        <div class="avatar-bubble"></div>
        <div class="position-relative w-100">
          <el-input
              ref="content"
              v-model="form.quote"
              type="textarea"
              :placeholder="$t('post.placeholders.quote')"
              :autosize="{ minRows: 3, maxRows: 5 }"
          >
          </el-input>

          <div class="emoji-picker">
            <emoji-picker @select="selectEmoji($event)">
              <i class="icon-emoji"/>
            </emoji-picker>
          </div>
        </div>
      </div>
    </el-form-item>
  </el-form>
</template>

<script>
import {mapGetters, mapState} from 'vuex';
import {step} from '@/mixins/post/step';
import EmojiPicker from '@/components/feed/post/EmojiPicker.vue';

export default {
  name: 'QuoteStep',
  components: {EmojiPicker},
  mixins: [step],
  computed: {
    ...mapGetters('auth', ['USER']),
    ...mapState('promo', {
      form: ({form}) => form,
    }),
  },
  data() {
    return {
      rules: {
        quote: [
          {required: false, message: this.$t('post.errors.title')}
        ],
      },
    }
  },
  methods: {
    /**
     * Validation
     * @returns {boolean}
     */
    async validate() {
      let result = false;

      await this.$refs.form.validate((isValid) => result = isValid)

      this.$emit('update', {titlesCount: 1})

      return result
    },
    selectEmoji(emoji) {
      const insertText = emoji.native;
      if (!insertText.length) return;

      const textarea = this.$refs.content.$refs.textarea;
      const sentence = textarea.value;
      const len = sentence.length;
      let pos = textarea.selectionStart;
      if (pos === undefined) {
        pos = 0;
      }

      const before = sentence.substr(0, pos);
      const after = sentence.substr(pos, len);

      this.form.quote = before + insertText + after;

      this.$nextTick().then(() => {
        textarea.selectionStart = pos + insertText.length;
      });
    },
  }
}
</script>
