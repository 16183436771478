<template>
  <div class="copied-code cursor-pointer" v-if="codeToShow" @click="copyCode">
    {{ codeToShow }}
  </div>
</template>

<script>
import {discountCodes} from '@/mixins/post/discountCodes';
export default {
  name: 'copied-code-button',
  mixins: [discountCodes],
  props: {
    code: {
      type: String,
      default: ''
    },
    id: { // Post ID
      type: Number
    },
  },
  data() {
    return {
      codeToShow: this.code,
      notificationDuration: 5000
    }
  },
  mounted() {
    if (!this.code) {
      // request unique discount code
      this.axios.put('/posts/' + this.id + '/discount-code').then((response) => {
        this.codeToShow = response.data.data.discount_code;
      }).catch((e) => {
        if (404 === e.response.status) {
          this.codeToShow = 'NOT FOUND';
        }
      });
    }
  },
  methods: {
    copyCode() {
      this.$copyText(this.codeToShow, undefined, () => {
        this.notifyDiscountCodeCopied(this.codeToShow)
      })
    }
  },
}
</script>
