<template>
  <default-grid :show-right-container="false" :show-left-container="false">

    <template v-slot:main-container>
      <div class="settings-page">
        <div v-if="!xsOnly" class="settings-vector">
          <img v-lazy="'@/assets/images/promohunter_vecor.png'" alt="">
        </div>
        <div class="settings-card">
          <router-localized-link :to="{name: 'home'}">
            <span class="settings-page-close icon-close-4"></span>
          </router-localized-link>

          <img class="settings-icon" v-lazy="'@/assets/images/settings.svg'" alt="">
          <div class="settings-header">
            <span v-t="'menu.user_settings.header'"></span>
          </div>

          <el-form :model="form"
                   ref="form"
                   :rules="rules"
                   label-position="left">
            <el-row>
              <el-col :xs="24" :sm="24" :md="11" :lg="11" :xl="11">
                <el-form-item prop="fname" :label="$t('auth.fname')">
                  <el-input class="rounded-input"
                            v-model="form.fname"
                            :placeholder="$t('auth.fname')"/>
                </el-form-item>
              </el-col>
              <el-col :span="2" v-if="!xsOnly"></el-col>
              <el-col :xs="24" :sm="24" :md="11" :lg="11" :xl="11">
              <el-form-item prop="lname" :label="$t('auth.lname')">
                  <el-input class="rounded-input"
                            v-model="form.lname"
                            :placeholder="$t('auth.lname')"></el-input>
                </el-form-item>
              </el-col>
            </el-row>

            <div class="specific-settings-wrapper">
              <div :class="{'d-flex': hasSocialAccount, 'justify-content-between': !xsOnly && hasSocialAccount, 'flex-column': xsOnly && hasSocialAccount, 'align-items-center': xsOnly && hasSocialAccount}">
                <div class="specific-settings-container" :class="{'w-100': !hasSocialAccount}">
                  <birthday-picker :date="form.birthday" @input="birthdayChanged"/>

                  <el-row>
                    <el-col :span="9" :xs="11">
                      <el-form-item prop="gender" :label="$t('menu.user_settings.fields.gender.label')">
                        <el-select v-model="form.gender" class="rounded-select">
                          <el-option
                              :key="1"
                              :label="$t('menu.user_settings.fields.gender.options.male')"
                              :value="1">
                          </el-option>
                          <el-option
                              :key="0"
                              :label="$t('menu.user_settings.fields.gender.options.female')"
                              :value="0">
                          </el-option>
                        </el-select>
                      </el-form-item>
                    </el-col>
                    <el-col :span="1"/>
                    <el-col :span="14" :xs="12">
                      <el-form-item prop="postal_code" :label="$t('menu.user_settings.fields.postal_code.label')">
                        <el-input class="rounded-input"
                                  v-model="form.postal_code"
                                  :placeholder="$t('menu.user_settings.fields.postal_code.placeholder')"/>
                      </el-form-item>
                    </el-col>
                  </el-row>

                  <el-row v-if="!hasSocialAccount">
                    <el-col :span="24">
                      <el-form-item prop="upload_avatar" :label="$t('auth.upload_avatar')">
                        <el-button class="w-100 upload-avatar-button font-weight-normal"
                                   text="plain"
                                   @click="triggerAvatarUpload">
                          <slot name="default">
                            <div v-t="'menu.user_settings.fields.avatar.upload_button'"/>
                          </slot>
                          <slot name="icon">
                            <i class="icon-avatar"/>
                          </slot>
                        </el-button>
                      </el-form-item>
                    </el-col>
                  </el-row>
                </div>

                <el-avatar v-if="hasSocialAccount" shape="circle" class="author-avatar">
                  <img v-lazy="avatarPreview"/>
                </el-avatar>
              </div>

              <template v-if="!hasSocialAccount">
                <div class="upload-avatar-container centered">
                  <el-upload
                      ref="avatar_uploader"
                      class="avatar-uploader"
                      accept="image/png,image/jpg,image/jpeg"
                      :action="uploadImageUrl"
                      :headers="{'Accept':'application/json'}"
                      :on-remove="handleImageRemove"
                      :on-error="handleImageError"
                      :on-change="handleImageChange"
                      :show-file-list="false"
                  >
                    <img v-if="avatarPreview" :src="avatarPreview" class="avatar" ref="trigger_avatar">
                    <div v-else class="avatar-uploader-icon" ref="trigger_avatar">
                      <i class="icon-avatar"/>
                    </div>
                  </el-upload>
                </div>
              </template>
            </div>

            <el-form-item prop="instagram" :label="$t('menu.user_settings.fields.instagram.label')">
              <el-input class="rounded-input"
                        v-model="form.instagram"
                        :placeholder="$t('menu.user_settings.fields.instagram.placeholder')"/>
            </el-form-item>
            <el-form-item prop="facebook" :label="$t('menu.user_settings.fields.facebook.label')">
              <el-input class="rounded-input"
                        v-model="form.facebook"
                        :placeholder="$t('menu.user_settings.fields.facebook.placeholder')"/>
            </el-form-item>
            <el-form-item prop="profile_title" :label="$t('menu.user_settings.fields.profile_title.label')">
              <el-input class="rounded-input"
                        v-model="form.profile_title"
                        :placeholder="$t('menu.user_settings.fields.profile_title.placeholder')"/>
            </el-form-item>

            <el-form-item prop="receive_replies_on_email">
              <el-checkbox v-model="form.receive_replies_on_email" class="big"
                           :label="$t('menu.user_settings.subscribe_checkbox')"/>
            </el-form-item>

            <el-form-item v-if="false"> <!-- need verification callback-->
              <el-checkbox v-model="changeEmailCheckbox" class="big"
                           :label="$t('menu.user_settings.change_email_checkbox')"/>
            </el-form-item>

            <el-form-item v-if="!hasSocialAccount">
              <el-checkbox v-model="changePasswordCheckbox" class="big">
                <span v-t="'menu.user_settings.change_password_checkbox'"></span>
                  <i class="icon-arrow-3 ms-2 d-inline-block" :class="{'rotate-90': changePasswordCheckbox}"/>
              </el-checkbox>
            </el-form-item>

            <el-form-item v-if="changeEmailCheckbox">
              <el-form-item prop="email" :label="$t('menu.user_settings.fields.email.label')">
                <el-input class="rounded-input" type="email"
                          v-model="form.email"
                          autocomplete="off"/>
              </el-form-item>
            </el-form-item>

            <template v-if="changePasswordCheckbox">
              <el-form-item prop="password" :label="$t('menu.user_settings.fields.password.label')">
                <el-input class="rounded-input" type="password"
                          v-model="form.password"
                          autocomplete="off"/>
              </el-form-item>

              <el-form-item prop="password_confirmation" :label="$t('menu.user_settings.fields.password_confirmation.label')">
                <el-input class="rounded-input" type="password"
                          v-model="form.password_confirmation"
                          autocomplete="off"/>
              </el-form-item>
            </template>

            <el-button type="primary"
                       class="w-100"
                       @click="submit">
              <span v-t="'menu.user_settings.submit_settings_btn'"/>
            </el-button>
          </el-form>
        </div>
      </div>
    </template>
  </default-grid>
</template>

<script>
import DefaultGrid from '@/views/DefaultGrid';
import BirthdayPicker from '@/components/header/auth/Fields/BirthdayPicker';
import RouterLocalizedLink from '@/components/common/RouterLocalizedLink';
import {validator} from '@/mixins/validator';
import {mapActions, mapGetters, mapState} from 'vuex';

export default {
  name: 'SettingsView',
  components: {
    DefaultGrid,
    BirthdayPicker,
    RouterLocalizedLink
  },
  mixins: [validator],
  data() {
    return {
      changeEmailCheckbox: false,
      changePasswordCheckbox: false,
      avatarPreview: '',
      hasSocialAccount: true,
      form: {
        avatar: null,
        email: null,
        password: null,
        password_confirmation: null,
        fname: '',
        lname: '',
        gender: 0,
        birthday: '',
        postal_code: '',
        receive_replies_on_email: false,
        instagram: '',
        facebook: '',
        profile_title: '',
      },
      rules: {
        email: [{required: true, validator: this.backendValidator}],
        password: [{required: true, validator: this.backendValidator}],
        password_confirmation: [{required: true, validator: this.backendValidator}],
        fname: [{required: true, validator: this.backendValidator}],
        lname: [{required: true, validator: this.backendValidator}],
        gender: [{required: true, validator: this.backendValidator}],
        birthday: [{required: true, validator: this.backendValidator}],
        postal_code: [{required: true, validator: this.backendValidator}],
        receive_replies_on_email: [{validator: this.backendValidator}],
        instagram: [{validator: this.backendValidator}],
        facebook: [{validator: this.backendValidator}],
        profile_title: [{validator: this.backendValidator}],
      }
    }
  },
  computed: {
    ...mapGetters('auth', [
      'USER',
    ]),
    ...mapState('dialogs', {
      visible: ({dialogs}) => dialogs.settings.visible,
    }),
    uploadImageUrl() {
      return process.env.VUE_APP_API_URL + 'register/upload-image';
    }
  },
  mounted() {
    this.setFormDataFromUser();
  },
  methods: {
    ...mapActions('auth', [
      'UPDATE_SETTINGS',
    ]),
    ...mapActions('loader', [
      'SET_LOADER',
    ]),
    birthdayChanged(value) {
      this.form.birthday = value;
    },
    submit() {
      this.SET_LOADER(true);
      this.UPDATE_SETTINGS(this.form).then((response) => {
        this.errors = {};
      }).catch((e) => {
        this.errors = e.response.data.errors;
      }).then(() => {
        this.SET_LOADER(false);
        this.$refs['form'].validate((valid) => {
          if (valid) {

            this.$notify.success({
              title: this.$t('notifications.settings_updated.title'),
              message: this.$t('notifications.settings_updated.message'),
              position: this.notificationPosition,
              duration: this.notificationDuration
            });
          }
          return false;
        });
      });
    },

    handleImageError(err, file, files) {
      const e = JSON.parse(err.message);

      this.$notify.error({
        title: this.$t('notifications.default.title'),
        message: _.get(e, 'errors.file.0', this.$t('notifications.default.message')),
        position: this.notificationPosition,
        duration: this.notificationDuration,
      });
    },

    handleImageChange(file) {
      this.avatarPreview = URL.createObjectURL(file.raw);
      this.form.avatar = '';

      if (_.get(file, 'response.file')) {
        this.form.avatar = _.get(file, 'response.file');
      }
    },

    handleImageRemove() {
      this.avatarPreview = '';
      this.form.avatar = '';
    },

    setFormDataFromUser() {
      this.hasSocialAccount = _.size(this.USER.social_accounts) > 0;
      this.form.gender = this.USER.gender;
      this.form.fname = this.USER.fname;
      this.form.lname = this.USER.lname;
      this.form.postal_code = this.USER.postal_code;
      this.form.receive_replies_on_email = Boolean(this.USER.receive_replies_on_email);
      this.form.birthday = this.USER.birthday;
      this.form.instagram = this.USER.metadata.instagram;
      this.form.facebook = this.USER.metadata.facebook;
      this.form.profile_title = this.USER.metadata.profile_title;
      if (this.USER.avatar_url) {
        this.avatarPreview = this.USER.avatar_url;
      }
    },
    triggerAvatarUpload() {
      this.$refs.trigger_avatar.click();
    }
  },
  watch: {
    USER(value) {
      this.setFormDataFromUser();
    },
    changeEmailCheckbox(value) {
      if (value) {
        this.form.email = this.USER.email;
      } else {
        this.form.email = null;
      }
    },
    changePasswordCheckbox(value) {
      if (!value) {
        this.form.password = null;
        this.form.password_confirmation = null;
      }
    }
  },
}
</script>
