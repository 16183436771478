<template>
  <div class="bonus-prize">
    <div class="bonus-prize-image-container">
      <img v-lazy="prize.image_url" alt="">
    </div>
    <b>{{ $t('prizes.prize_price', {amount: prize.price}) }}</b>
    <p>{{ prize.translation.title }}</p>

    <div class="text-prize text-center cursor-pointer"
         v-if="remainsPointsToCollect <= 0"
         @click="requestPrize">
      <span v-t="'prizes.enough_points'"></span>
      <i class="icon-arrow_forward"></i>
    </div>

    <div v-else class="text-points text-center">{{ $t('prizes.how_many_points_needed', {amount: remainsPointsToCollect}) }}</div>
  </div>
</template>

<script>
import {mapGetters} from 'vuex';

export default {
  name: 'bonus-prize',
  props: ['prize'],
  computed: {
    ...mapGetters('auth', [
      'USER',
    ]),

    remainsPointsToCollect() {
      return this.prize.price - this.USER.account.available;
    },
    isEnoughPoints() {
      return this.USER.account.available >= this.prize.price;
    }
  },
  methods: {
    requestPrize() {
      this.routerPush({name: 'prizes-pool.prize-request', params: {prizeId: String(this.prize.id)}});
    }
  }
}
</script>

<style lang="scss" scoped>
.bonus-prize {
  background-color: white;
  margin: 10px;
  padding: 10px;
}
</style>
