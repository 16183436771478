<template>
  <copied-code-button
      v-if="isCodeCopied"
      :code="discount_code"
      :id="promoId"
  />
  <copy-code-button
      v-else
      :id="promoId"
      :code="discount_code"
      :url="discount_url"
      :event-outgoing-click-meta="eventOutgoingClickMeta"
      :btn-class="copyBtnClass"
      @click="$emit('opened')"
  />
</template>

<script>
import CopyCodeButton from '@/components/feed/buttons/CopyCodeButton.vue';
import CopiedCodeButton from '@/components/feed/buttons/CopiedCodeButton.vue';
import {discountCodes} from '@/mixins/post/discountCodes';

export default {
  name: 'CopyButtonGroup',
  mixins: [discountCodes],
  components: {CopiedCodeButton, CopyCodeButton},
  props: {
    promoId: {
      type: Number,
      required: true
    },
    discount_codes_count: {
      type: Number,
      required: true
    },
    discount_code: {
      type: String,
      default: '',
      required: true
    },
    discount_url: {
      type: String,
      default: '',
      required: true
    },
    copyBtnClass: {
      type: String,
      default: '',
    },
    eventOutgoingClickMeta: {
      type: Object,
      default: () => {
      }
    },
  },
  data() {
    return {
      discountCodeCopied: true,
      discountPromoId: null,
      notificationDuration: 5000
    }
  },
  computed: {
    isCodeCopied() {
      return this.discountCodeCopied && +this.discountPromoId === +this.promoId && (this.discount_code || this.discount_codes_count)
    }
  },

  mounted() {
    this.discountPromoId = localStorage.getItem('CopiedDiscountCodeId')
    if (+this.discountPromoId === +this.promoId) {
      this.loadDiscountCode(this.discountPromoId);
      localStorage.removeItem('CopiedDiscountCodeId');
    }
  },
  methods: {
    loadDiscountCode(id) {
      axios.get('/feed-items?post=' + id).then((response) => {
        this.discountCode = _.get(response, 'data.data[0].data', null);
        if (this.discountCode) {
          this.discountCodeCopied = true;
          this.notifyDiscountCodeCopied(this.discount_code)
        }
      });
    },
  },
}
</script>


<style scoped>

</style>
