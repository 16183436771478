export default {
    mounted(el, binding) {
        const loadImage = (preloadEl) => {
            const img = preloadEl || el
            if (img.dataset.loaded) return false
            img.src = preloadEl?  img.dataset.src :  binding.value;
            img.dataset.loaded = true;

            if (img.dataset.style) {
                const styles = JSON.parse(img.dataset.style);
                // special case for cropped images feature in posts.
                Object.keys(styles).forEach((key) => {
                    img.style.setProperty(key, styles[key]);
                });
            }
        };

        if ('IntersectionObserver' in window) {
            const observer = new IntersectionObserver((entries, observer) => {
                entries.forEach(entry => {
                    if (entry.isIntersecting) {
                        loadImage();
                        observer.unobserve(el);

                        // Get the next 3 images and observe them
                        const currentImageIndex = [...document.querySelectorAll('img.lazy-load')].indexOf(entry.target);
                        const images = document.querySelectorAll('img.lazy-load');
                        const nextImages = Array.from(images).slice(currentImageIndex + 1, currentImageIndex + 4);

                        nextImages.forEach((img) => {
                            if (!img.dataset.loaded) {
                                loadImage(img);
                                img.dataset.loaded = true;
                                observer.observe(img);
                            }
                        });
                    }
                });
            });
            observer.observe(el);
        } else {
            // Fallback for browsers without IntersectionObserver
            loadImage();
        }
    }
};