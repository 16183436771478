<template>
  <div class="single-advertiser-container">
      <div v-if="randomAds" @click="goAd(randomAds.url)">
        <div class="title">
          {{ randomAds.title }}
        </div>
        <img v-lazy="randomAds.image_url">
      </div>
  </div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex';

export default {
  name: 'single-advertiser',
  mounted() {
    this.FETCH_ADS();
  },
  computed: {
    ...mapGetters('ad', [
      'ADS',
    ]),

    randomAds(){
        return _.sample(this.ADS);
    }
  },
  methods: {
    ...mapActions('ad', [
      'FETCH_ADS'
    ]),

    goAd(url) {
      window.open(url, '_blank');
    }
  }
}
</script>
