
<template>
  <span v-if="storeTitle" class="store-label line-clamp-1" v-text="storeTitle" @click="showStore"/>
  <span v-else-if="webshopTitle" class="store-label line-clamp-1" v-text="webshopTitle" @click="showWebshop"/>
</template>

<script>
import {mapGetters} from 'vuex';

export default {
  name: 'PostPromoWebshopLabel',
  props: {
    webshop: {
      type: Object,
      default: ()=>{}
    },
    webshops: {
      type: Array,
      default: ()=>[]
    },
    stores: {
      type: Array,
      default: ()=>[]
    },
  },
  computed: {
    ...mapGetters('auth', [
      'GUEST',
    ]),
    /**
     * Store title
     * @returns {*|string}
     */
    storeTitle() {
      let storeName = ''
      if (this.stores?.length) {
        storeName =  _.get(this, 'stores[0].translation.title')
      }
      return storeName ? storeName : ''
    },
    /**
     * Webshop title
     * @returns {*|string}
     */
    webshopTitle() {
      let webshop = this.webshop;
      if (!webshop && this.webshops.length){
        webshop = this.webshops[0];
      }

      return webshop ? webshop.translation.title : ''
    },
  },
  methods: {
    showWebshop(){

      let webshop = this.webshop;
      if (!webshop && this.webshops.length){
        webshop = this.webshops[0];
      }

      const routeData = {name: 'webshop.show', params: {webshopSlug: webshop.translation.route}}

      this.gotoLink(routeData)
    },

    showStore(){
      let storeSlug = ''
      if (this.stores?.length) {
        storeSlug =  _.get(this, 'stores[0].translation.route')
      }
      const routeData = {name: 'store.show', params: {storeSlug: storeSlug}}

      this.gotoLink(routeData)
    },

    gotoLink(routeData){
      if (!this.GUEST) {
        this.routerPush(routeData);

      }else{
        const href = this.$router.resolve(routeData).href;
        window.open(href, '_self');
      }
    }

  },
}
</script>


<style scoped>

</style>
