<template>
  <div class="success-creation">
    <div class="success-container ms-3.5">
      <div class="img-wrapper">
        <img src="@/assets/images/promoman_success.png" alt="" loading="lazy">
      </div>

      <div v-if="bonus" class="coins">
        <span class="me-0.5" v-text="bonus"/>
        <img class="h-100" src="@/assets/images/coins.png" alt="" loading="lazy">
      </div>
    </div>

    <div class="success-creation__title mb-3">
      <span v-t="'post.sent_successfully'"/>
    </div>

    <div class="success-creation__description p-2 mb-3">
      <span class="font-weight-medium" v-t="'post.success.title'"/>
      <br>
      {{ $t('post.success.description') }}
    </div>

    <el-button
      type="success"
      class="success-btn"
      @click="$emit('close')"
    >
      <span v-t="'post.back_to_site'"/>
    </el-button>
  </div>
</template>

<script>

export default {
  name: 'SuccessCreation',
  props: {
    bonus: [Number, String]
  },
}
</script>
