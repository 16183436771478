<template>
  <div class="filter-container">
    <div class="filter-header text-dark" @click="openFilter">
      <span class="filter-header__text" v-t="'filters.price'"/>
      <i class="trans-2" :class="filterOpened ? 'icon-arrow-3 rotate-90' : 'icon-arrow-3'"/>
    </div>
    <div class="filter-options px-3" v-if="filterOpened">
      <div class="price-slider">
        <el-slider
          class="text-dark"
          v-model="range"
          :step="0.01"
          :max="max"
          :min="min"
          :format-tooltip="(v) => v === defaultMax ? `${v}+` : v"
          range
          @change="filterPrice"
        />
      </div>
    </div>
    <el-divider class="bold-divider my-3"/>
  </div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex';

export default {
  name: 'price-filter',
  props: {
    prices: {
      type: Array,
      default() {
        return []
      }
    },
  },
  data() {
    return {
      min: 0,
      max: 0,
      defaultMax: 5000,
      realMax: 5000,
      range: [0, 0],
    };
  },
  computed: {
    /**
     * mapGetters
     */
    ...mapGetters('filter', [
      'OPENED_FILTERS',
    ]),
    ...mapGetters('promo', [
      'PROMOS_PRICES',
    ]),
    priceFilter() {
      return _.get(this.$router.currentRoute.value.query, 'price_filter[]', [])
    },
    defaultFilterValue() {
      return _.castArray(this.priceFilter)
    },

    /**
     * Is filter opened
     * @returns {*}
     */
    filterOpened() {
      return _.get(this.OPENED_FILTERS, 'price_filter', true);
    }
  },
  mounted() {
    const prices = this.PROMOS_PRICES.length ? this.PROMOS_PRICES : this.prices
    this.initFilter(prices);
  },
  methods: {
    ...mapActions('filter', [
      'SET_FILTER_VALUE',
      'SET_FILTER_OPENED',
    ]),

    filterPrice() {
      const range = this.range;
      // Setting REAL MAX VALUE
      if (this.range[1] === this.defaultMax) {
        range[1] = this.realMax
      }

      this.SET_FILTER_VALUE({price_filter: range});
    },

    initFilter(data) {
      const min = Math.floor(data[0]);
      const max = Math.floor(data[1]);
      if (this.range[0] === 0 && this.range[1] === 0 && max > 0) {
        this.range = [min, max];
      }
      this.min = min;
      this.max = max > this.defaultMax ? this.defaultMax : max;
      this.realMax = max;
    },
    initRange(min, max) {
      if (!min || !max) {
        return;
      }

      this.range = [parseFloat(min), parseFloat(max)];
    },
    reset() {
      this.range = [this.min, this.defaultMax]
    },
    openFilter() {
      const filter = {};
      _.set(filter, 'price_filter', !this.filterOpened);
      this.SET_FILTER_OPENED(filter);
    }
  },
  watch: {
    PROMOS_PRICES(to, from) {
      this.initFilter(to);
    },
    priceFilter(newVal) {
      if (!_.isEmpty(newVal) && _.size(newVal) === 2) {
        this.initRange(this.defaultFilterValue[0], this.defaultFilterValue[1]);
      }
    }
  }
}
</script>
