<template>
  <div class="folder">
    <div class="folder-header">
      <div class="folder-author">
        <el-avatar shape="circle" class="folder-author-avatar">
          <img src="@/assets/images/fb_logo.png" alt="" loading="lazy"/>
        </el-avatar>
        <div class="folder-author-info">
          <div class="folder-author-name" v-t="'header.title'"/>
          <div class="folder-time" v-text="folder.time_ago"/>
        </div>
      </div>

      <div class="folder-actions">
        <div class="folder-favourite">
          <i
            class="cursor-pointer"
            :class="{'active icon-heart-2': folder.is_favourite, 'icon-heart': !folder.is_favourite}"
            @click="processFavourite"
          />
        </div>
      </div>

    </div>

    <div class="folder-content">
      <el-divider class="mb-3"/>

      <div class="my-3">
        {{ prefixDescription }}
        <router-localized-link :to="{name: 'folders.store', params: {storeSlug: String(folder.store.translation.route)}}">
          {{ folder.store.translation.title }}
        </router-localized-link>
        {{ postfixDescription }}
      </div>

      <div class="folder-container">
        <img class="folder-image" v-lazy="folder.image_url" alt="">
      </div>
      <div class="folder-additional-container">
        <el-button
            type="primary"
            @click="goFolder"
        >
          {{ $t('promo.view_the_folder') }}
          <i class="icon-arrow-3"/>
        </el-button>
      </div>
    </div>

    <reactions
      class="px-3.25"
      :rating="folder.hits.count"
      :is-like="folder.is_hit"
      :comments-count="commentsCount"
      @react="$refs.commentForm.focusTextarea()"
      @hit="processHit"
      @share="processShare"
    />

    <div class="folder-footer">
      <div class="comments-wrapper">
        <comments-header
          :hidden-total="hiddenComments"
          :total="commentsCount"
          :total-visible="false"
          @show-more="showMoreComments"
        />

        <comments-loading :loading="commentsLoading"/>

        <comments :comments-data="visibleCommentsData" @hit="hitCommentProcess"/>

        <comments-loading :loading="commentsPushing"/>

        <comment-form ref="commentForm" @submit="pushComment"/>
      </div>
    </div>
  </div>
</template>

<script>
import CommentsLoading from '@/components/feed/comment/CommentsLoading';
import CommentsHeader from '@/components/feed/comment/CommentsHeader';
import Comments from '@/components/feed/comment/Comments';
import CommentForm from '@/components/feed/comment/CommentForm';
import {comments} from '@/mixins/comments';
import {needAuth} from '@/mixins/auth/needAuth';
import {actions} from '@/mixins/post/actions';
import Reactions from '@/components/feed/Reactions';
import {share} from '@/mixins/post/share';
import RouterLocalizedLink from '@/components/common/RouterLocalizedLink';

export default {
  name: 'Folder',
  components: {RouterLocalizedLink, Reactions, CommentsLoading, CommentsHeader, Comments, CommentForm},
  props: {
    folder: Object,
    vuexModule: {
      type: String,
      default: 'promo',
    },
  },
  mixins: [comments, needAuth, actions, share],
  data() {
    return {
      descriptionSalt: Math.floor(Math.random() * 2 + 1), // 1,2
    }
  },
  computed: {
    prefixDescription(){
      return this.$t('folder.folder_description.prefix.' + this.descriptionSalt);
    },
    postfixDescription(){
      return this.$t('folder.folder_description.postfix.' + this.descriptionSalt);
    },
    entityData() {
      return {
        entity: this.folder,
        morphable_type: 'Folder'
      }
    }
  },
  mounted() {
    this.commentsCount = this.folder.comments_count;
    this.comments = this.folder.comments;
    this.visibleComments = _.min([this.xsOnly ? 0 : 2, this.folder.comments.length]);
  },
  methods:{
    processShare(destination) {
      const link = process.env.VUE_APP_URL + this.getFolderPath();
      this.shareLink(link, destination);
    },
    showMoreComments() {
      this.showMoreCommentsProcess('Folder', this.folder.id);
    },
    pushComment(form) {
      this.pushCommentProcess('Folder', this.folder.id, form);
    },
    goFolder() {
      this.routerPush({
        name: 'folder.view',
        params: {
          storeSlug: this.folder.store.translation.route,
          folderDate: this.folder.valid_from,
        },
        query: {
          id: this.folder.id
        }
      });
    },
    getFolderPath() {
      return this.$router.resolve({
        name: 'folder.view',
        params: {
          storeSlug: this.folder.store.translation.route,
          folderDate: this.folder.valid_from,
        },
        query: {
          id: this.folder.id
        }
      }).fullPath.substring(1);
    }
  }
}
</script>
