

<template>
  <span v-if="webshopTitle" class="store-label line-clamp-1" v-text="webshopTitle" @click="showWebshop"/>

</template>

<script>
import {mapGetters} from 'vuex';

export default {
  name: 'OnlinePromoWebshopLabel',
  props: {
    webshop: {
      type: Object,
      default: ()=>{}
    },
  },
  computed: {
    ...mapGetters('auth', [
      'GUEST',
    ]),
    /**
     * Store title
     * @returns {*|string}
     */
    webshopTitle() {

      return this.webshop ? this.webshop.translation.title : ''
    },
  },
  methods: {
    showWebshop(){

      const routeData = {name: 'webshop.show', params: {webshopSlug: this.webshop.translation.route}}

      if (!this.GUEST) {
        this.routerPush(routeData);
      }else {
        const href = this.$router.resolve(routeData).href;
        window.open(href, '_self');
      }
    }
  },
}
</script>

<style scoped>

</style>
